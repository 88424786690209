import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';

/**
 * Formulaire qui s'affiche quand le dernier élément cliqué est une sous-catégorie.
 */
const SubCategoryForm = (props) => {
  const [input, setInput] = useState(props.subCategory ? props.subCategory.name : '');
  return (
    <Form>
      <Form.Input
        label='Nom de la sous-catégorie'
        onChange={(e, {value}) => setInput(value)}
        value={input}
      />
      <Form.Group inline>
        <Form.Button
          primary
          onClick={() => {
            let result = {...props.subCategory};
            result.name = input;
            props.onChange(result);
            console.log(result);
          }}
        >Confirmer</Form.Button>
        <Form.Button
          color='red'
          onClick={() => props.onDelete(props.subCategory.id)}
        >Supprimer</Form.Button>
      </Form.Group>
    </Form>
  );
}

export default SubCategoryForm;