import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import { Grid, Message, Loader, Button, Form } from 'semantic-ui-react';

import LeftCarte from './LeftCarte';
import CategoryPicker from './CategoryPicker';
import ProductPicker from './ProductPicker';
import { createDefaultCategories } from '../../config/carte';
import CSVReader from './CSVReader';

// firebase call hook
const useCarte = (restoId) => {
  const [response, setResponse] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await firebase.firestore().collection('Restaurant').doc(restoId).get();
        if (res.exists) {
          setResponse(res.data());
        }
      } catch (error) {
        setError(error);
      }
    }
    fetchData();
  }, [restoId]);

  return [response, error];
};

const Carte = (props) => {
  const [loadBackup, setLoadBackup] = useState(false); // Affiche le menu backup si true
  const [restoIdInput, setRestoIdInput] = useState(''); // State pour l'input de l'id restaurant
  const [restoId, setRestoId] = useState(props.resto.id); // Id du restaurant du menu. Le hook useCarte sera appelé si l'id change
  const [response, error] = useCarte(restoId); // Hook qui va faire la requete a la base de donnees

  const [isLoading, setIsLoading] = useState(true);

  const [categories, setCategories] = useState([]); // Array de tous les catégories
  const [selectedCategory, setSelectedCategory] = useState(null); // La catégorie séléctionnée

  const [subCategories, setSubCategories] = useState([]); // Array de tous les sous-catégories
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // La sous-catégorie séléctionnée

  const [products, setProducts] = useState([]); // Array de tous les produits
  const [selectedProduct, setSelectedProduct] = useState(null); // Le produit séléctionné

  const [csvProducts, setCsvProducts] = useState([]); // Array des produits csv

  const [productClipboard, setProductClipboard] = useState(null); // Le produit qui est séléctionné pour copier ou déplacer

  const [lastClicked, setLastClicked] = useState({}); // Le dernier élément qui a été cliqué (catégorie, sous-catégorie ou produit)

  // message: { type: 'success' || 'error, message: string }
  const [message, setMessage] = useState(null);

  // Effet appelé a chaque fois que reponse ou loadBackup change de valeur. Permet d'initialiser les state categories, subCategories et products
  useEffect(() => {
    if (error !== null) {
      makeMessageNotification({ type: 'error', message: 'Une erreur s\'est produite lors du chargement du menu. Le menu n\'a pas pu être chargée' });
      setIsLoading(false);
      return;
    }

    let menu;
    // set menu to backupMenu if loadbackup is true
    if (loadBackup)
      menu = response.backupMenu || {};
    else
      menu = response.menu || {};

    if (!menu) {
      makeMessageNotification({ type: 'error', message: 'Le menu n\'existe pas' });
      setIsLoading(false);
      return;
    }

    // set categories
    let categories = null;

    if (!menu.categories || menu.categories.length === 0)
      categories = createDefaultCategories();
    else
      categories = menu.categories;

    setCategories(categories);
    setSelectedCategory(categories[0]);
    setLastClicked({
      type: 'category',
      selectedItem: categories[0],
    });

    // set subCategories
    let subCategories = menu.subCategories || [];

    setSubCategories(subCategories);
    setSelectedSubCategory(subCategories.length > 0 ? subCategories[0] : { position: 1 });

    // set products
    let products = menu.products || [];
    setProducts(products);

    setIsLoading(false);
  }, [response, error, loadBackup]);

  // Affiche une popup pendant 3 secondes avec le message passé en paramètre
  const makeMessageNotification = useCallback((msg) => {
    setMessage(msg);
    setTimeout(() => setMessage(null), 3000);
  }, []);

  // Appelé quand la catégorie séléctionnée change
  const selectedCategoryChangeCallback = useCallback((newCategory) => {
    setSelectedCategory(newCategory);
    setSelectedProduct(null);
    setLastClicked({
      type: 'category',
      selectedItem: newCategory,
    });

    // find first subcategory of selectedcategory
    let newSubCategory = { position: 99 };
    for (let i = 0; i < subCategories.length; ++i) {
      if (subCategories[i].categoryId === newCategory.id) {
        if (subCategories[i].position < newSubCategory.position)
          newSubCategory = subCategories[i];
      }
    }

    if (newSubCategory.id)
      setSelectedSubCategory(newSubCategory);
    else
      setSelectedSubCategory({ position: 1 });
  }, [subCategories]);

  // Appelé quand la souscatégorie séléctionnée change
  const selectedSubCategoryChangeCallback = useCallback((newSubCategory) => {
    setSelectedSubCategory(newSubCategory);
    setSelectedProduct(null);
    setLastClicked({
      type: 'subcategory',
      selectedItem: newSubCategory,
    });
  }, []);

  // Appelé quand le produit séléctionné change
  const selectedProductChangeCallback = useCallback((newProduct) => {
    setSelectedProduct(newProduct);
    setLastClicked({
      type: 'product',
      selectedItem: newProduct,
    });
  }, []);

  // Fonction permettant d'insérer ou de mettre à jour un produit danx l'array products
  const insertOrUpdateProduct = useCallback((item) => {
    // set color
    item.color = item.color || selectedCategory.color;

    // compute ht price
    item.priceHt = item.priceTtc / ((100 + item.vatRate) / 100);

    // check if product id already exists
    let newProducts = [...products];
    const indexProduct = newProducts.findIndex(e => e.id === item.id);

    // item doesnt exist
    if (indexProduct === -1) {
      let id;
      if (products.length === 0) {
        id = 1
      } else {
        const tempProducts = [...products];
        tempProducts.sort((a, b) => b.id - a.id);
        id = tempProducts[0].id + 1;
        console.log(tempProducts);
      }
      item.id = id;
      newProducts.push(item);
    }
    else
      newProducts[indexProduct] = item;

    selectedProductChangeCallback(item);
    setProducts(newProducts);
  }, [selectedCategory, products, selectedProductChangeCallback]);

  // Appelé quand l'utilisateur confirme sur le formulaire de gauche
  const leftPaneChange = useCallback((item) => {
    // if last click is a category
    if (lastClicked.type === 'category') {
      let newCategories = [...categories];
      const indexCategory = newCategories.findIndex(e => e.id === item.id);

      if (indexCategory === -1)
        newCategories.push(item);
      else
        newCategories[indexCategory] = item;

      setCategories(newCategories);
      setSelectedCategory(item);
    }
    // if last click is a subcategory
    else if (lastClicked.type === 'subcategory') {
      let newSubCategories = [...subCategories];

      // find subcategory index
      const indexSubCategory = newSubCategories.findIndex(e => e.id === item.id);

      // item doesnt exist
      if (indexSubCategory === -1) {
        let id;
        if (subCategories.length === 0)
          id = 1;
        else {
          // reverse sort subcategories array and take first element id and add 1 for new id
          const tempSubCategories = [...subCategories];
          tempSubCategories.sort((a, b) => b.id - a.id);
          id = tempSubCategories[0].id + 1;
        }

        item.id = id;
        item.categoryId = selectedCategory.id;
        newSubCategories.push(item);
      }
      else
        newSubCategories[indexSubCategory] = item;

      setSelectedSubCategory(item);
      setSubCategories(newSubCategories);
    }
    // if last click is a product
    else if (lastClicked.type === 'product') {
      insertOrUpdateProduct(item);
    }

    makeMessageNotification({
      type: 'success',
      message: 'Mise à jour réussie.',
    });
  }, [lastClicked, categories, subCategories, selectedCategory, insertOrUpdateProduct, makeMessageNotification]);

  // Appelé quand l'utilisateur supprime sur le formulaire de gauche
  const leftPaneDelete = useCallback(id => {
    if (lastClicked.type === 'category') {
      // check if product exist in category
      if (products.some(p => p.categoryId === id)) {
        makeMessageNotification({
          type: 'error',
          message: 'Il reste des produits dans cette catégorie. Suppression impossible.',
        });
      }
      else {
        const newCategories = [...categories];
        const deleteCategoryIndex = newCategories.findIndex(e => id === e.id);
        newCategories[deleteCategoryIndex].name = '';

        setCategories(newCategories);
      }
    }
    else if (lastClicked.type === 'subcategory') {
      // check if product exist in subcategory
      if (products.some(p => p.subCategoryId === id)) {
        makeMessageNotification({
          type: 'error',
          message: 'Il reste des produits dans cette sous-catégorie. Suppression impossible.'
        });
      }
      else {
        const newSubCategories = [...subCategories];
        const deleteSubCategoryIndex = newSubCategories.findIndex(e => id === e.id);
        newSubCategories[deleteSubCategoryIndex].name = '';

        setSubCategories(newSubCategories);
      }
    }
    else if (lastClicked.type === 'product') {
      const newProducts = [...products];
      const deleteProductIndex = newProducts.findIndex(e => id === e.id);
      newProducts.splice(deleteProductIndex, 1);

      const newSelectedProduct = {
        position: selectedProduct.position,
        categoryId: selectedProduct.categoryId,
        subCategoryId: selectedProduct.subCategoryId,
        color: selectedCategory.color
      };

      selectedProductChangeCallback(newSelectedProduct)
      setProducts(newProducts);
    }
  }, [lastClicked, categories, subCategories, products, selectedProduct, selectedCategory, makeMessageNotification, selectedProductChangeCallback]);

  // Appelé quand l'utilisateur séléctionne un produit pour le copier ou le déplacer
  const clipboardChangeCallback = useCallback(() => {
    setProductClipboard(selectedProduct);
  }, [selectedProduct]);

  // Appelé quand l'uilisateur déséléctionne un produit
  const clipboardDeselectCallback = useCallback(() => {
    setProductClipboard(null);
  }, []);

  // Appelé quand l'utilisateur copie OU DEPLACE le produit séléctionné -- type: 'copy' || 'move'
  const clipboardCopyCallback = useCallback((type) => {
    const newProduct = { ...productClipboard };
    delete selectedProduct.color;
    Object.assign(newProduct, selectedProduct);
    if (type === 'copy')
      newProduct.id = null;
    if (type === 'move') {
      setProductClipboard(newProduct);
    }
    insertOrUpdateProduct(newProduct);

  }, [productClipboard, selectedProduct, insertOrUpdateProduct]);

  // Appelé quand l'utilisateur charge le menu du restaurant avec l'id en input
  const loadOtherRestoMenu = useCallback((backup) => {
    firebase.firestore().collection('Restaurant').doc(restoIdInput).get().then(res => {
      if (res.exists) {
        const id = restoIdInput;
        setRestoIdInput('');
        setRestoId(id);
        setLoadBackup(backup);
      }
      else {
        makeMessageNotification({
          type: 'error',
          message: 'Le restaurant n\'existe pas.',
        });
      }
    });
  }, [restoIdInput])

  // Sauvegarde le menu actuel sur firebase
  const saveToDatabase = useCallback(() => {
    const payload = {
      'menu.categories': categories,
      'menu.subCategories': subCategories,
      'menu.products': products,
      'menu.savedAt': firebase.firestore.Timestamp.now()
    };
    firebase.firestore().collection('Restaurant').doc(props.resto.id).update(payload)
      .then(() => {
        makeMessageNotification({
          type: 'success',
          message: 'La carte a été sauvegardée',
        });
      })
      .catch(() => {
        makeMessageNotification({
          type: 'error',
          message: 'Une erreur s\'est produite lors de la sauvegarde. La carte n\'a pas été sauvegardée',
        });
      });
  }, [products, categories, subCategories, props]);

  if (isLoading)
    return (<Loader size='huge' active />)

  return (
    <div style={{ margin: '0 auto', padding: 50, width: 1024, height: 700, backgroundImage: 'url("./ipad.png")', backgroundRepeat: 'no-repeat', backgroundSize: '900px 670px', backgroundPosition: '57px 6px' }}>
      <Grid padded style={{ padding: '0 50px', position: 'relative' }}>
        {message &&
          <div
            style={{ position: 'absolute', top: 0, left: '50%', transform: 'translate(-50%, 0)', zIndex: 1000 }}
          >
            <Message error={message.type === 'error'} success={message.type === 'success'}>{message.message}</Message>
          </div>
        }
        <LeftCarte
          type={lastClicked.type}
          selectedItem={lastClicked.selectedItem}
          clipboard={productClipboard}
          onChange={leftPaneChange}
          onDelete={leftPaneDelete}

          onClipboardChange={clipboardChangeCallback}
          onClipboardDeselect={clipboardDeselectCallback}
          onClipboardCopy={clipboardCopyCallback}
        />
        <Grid.Column width={10}>
          <Grid>
            <ProductPicker
              products={products}
              selectedProduct={selectedProduct}
              selectedCategory={selectedCategory}
              selectedSubCategory={selectedSubCategory}
              clipboard={productClipboard}

              onProductChange={selectedProductChangeCallback}
            />

            <CategoryPicker
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={selectedCategoryChangeCallback}

              subCategories={subCategories}
              selectedSubCategory={selectedSubCategory}
              onSubCategoryChange={selectedSubCategoryChangeCallback}
            />
          </Grid>
        </Grid.Column>
      </Grid>
      <Grid padded style={{ paddingTop: 40 }}>
        <Grid.Column width='16'>
          <Button
            color='yellow'
            fluid
            onClick={() => saveToDatabase()}
          >Sauvegarder dans la base de données</Button>
        </Grid.Column>
        <Grid.Column width='5'>
          <p><b>License du restaurant: {restoId}</b></p>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group inline>
              <label>Charger la backup</label>
              <Button color='green' onClick={() => setLoadBackup(true)}>Charger</Button>
            </Form.Group>
          </Form>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Input
              label={'Charger le menu d\'un autre restaurant'}
              placeholder='License du restaurant'
              onChange={(e, { value }) => setRestoIdInput(value)}
            />
            <Button
              color='green'
              disabled={!restoIdInput}
              onClick={() => loadOtherRestoMenu(false)}
            >Charger le menu</Button>
            <Button
              color='green'
              disabled={!restoIdInput}
              onClick={() => loadOtherRestoMenu(true)}
            >Charger la backup</Button>
          </Form>
        </Grid.Column>
      </Grid>
      <Grid padded style={{ paddingTop: 40 }}>
        <CSVReader
          restoId={restoId}
          setCsvProducts={setCsvProducts}
        />
      </Grid>
    </div>
  );
}

export default connect(
  (state) => ({
    resto: state.resto.currentResto
  }),
)(Carte);