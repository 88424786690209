import React, { useState, useCallback } from 'react';
import { Form, Button, Tab } from 'semantic-ui-react';
import ColorPicker from './ColorPicker';

import { createDefaultProduct, vatValues } from '../../config/carte';

/**
 * Formulaire qui s'affiche quand le dernier élément cliqué est un produit
 */
const DetailsTab = (props) => {
  const product = props.product;
  return (
    <Form>
      <Form.Input
        label='Nom produit'
        value={product.name}
        onChange={(e, { value }) => props.onChange(value, 'name')}
        maxLength='26'
      />
      <Form.Input
        label='Nom produit (cuisine)'
        value={product.kitchenName}
        onChange={(e, { value }) => props.onChange(value, 'kitchenName')}
      />
      <Form.Input
        label='Nom produit (application)'
        value={product.appName}
        onChange={(e, { value }) => props.onChange(value, 'appName')}
      />
      <Form.Input
        label='Code produit'
        value={product.code}
        onChange={(e, { value }) => props.onChange(value, 'code')}
      />
      <Form.Group grouped>
        <label>Type produit</label><br/>
        <Button.Group>
          <Button
            primary={product.type === 'product'}
            onClick={() => props.onChange('product', 'type')}
          >Produit</Button>

          <Button
            primary={product.type === 'option'}
            onClick={() => props.onChange('option', 'type')}
          >Option</Button>
        </Button.Group>
      </Form.Group>
      <Form.Group grouped>
        <label>Couleur</label><br/>
        <ColorPicker
          value={product.color}
          onChange={(color) => props.onChange(color, 'color')}
        />
      </Form.Group>
    </Form>
  );
}

const PrixTab = (props) => {
  const product = props.product;
  return (
    <Form>
      <Form.Group grouped>
        <label>Type de prix</label><br />
        <Button.Group>
          <Button
            primary={!product.gram && !product.manualPrice}
            onClick={() => props.onChange([false, false], ['gram', 'manualPrice'])}
          >Prix unitaire</Button>
          <Button
            primary={product.gram && !product.manualPrice}
            onClick={() => props.onChange([true, false], ['gram', 'manualPrice'])}
          >100 gr</Button>
          <Button
            primary={!product.gram && product.manualPrice}
            onClick={() => props.onChange([false, true], ['gram', 'manualPrice'])}
          >Manuel</Button>
        </Button.Group>
      </Form.Group>

      <Form.Group grouped>
        <label>Prix</label><br />
        <Form.Input
          type='number'
          style={{display: 'inline-block' }}
          placeholder='Prix net'
          value={product.priceTtc}
          onChange={(e, { value }) => props.onChange(parseFloat(value), 'priceTtc')}
        />
        <Button.Group>
          {vatValues.map(o => (
            <Button
              key={o.vatId}
              primary={o.vatId === product.vatId}
              onClick={() => props.onChange([o.vatId, o.vatRate], ['vatId', 'vatRate'])}
            >{o.vatRate.toFixed(2)}%</Button>
          ))}
        </Button.Group>
      </Form.Group>

      <Form.Group grouped>
        <label>Produit sans remise</label>
        <Form.Radio
          toggle
          checked={!product.canDiscount}
          onClick={() => props.onChange(!product.canDiscount, 'canDiscount')}
        />
      </Form.Group>
    </Form>
  )
}

const AutreTab = (props) => {
  const canSelect = props.product !== null && props.product.id;
  const canDeselect = props.clipboard && props.product && props.product.id === props.clipboard.id;
  const canCopy = !canDeselect && props.clipboard !== null && (props.product && !props.product.id);

  if (canDeselect)
    return (
      <Button
        fluid
        primary
        onClick={() => props.onClipboardDeselect()}
      >
        Déselectionner
      </Button>
    );

  if (canSelect)
    return (
      <Button
        fluid
        primary
        onClick={() => props.onClipboardChange()}
      >
        Selectionner
      </Button>
    );

  if (canCopy)
    return (
      <>
      <Button fluid primary
        onClick={() => props.onClipboardCopy('copy')}
      >
        Copier
      </Button>
      <Button fluid primary
        onClick={() => props.onClipboardCopy('move')}
      >
        Déplacer
      </Button>
      </>
    );

  return (
    <p>Selectionner un produit pour le déplacer</p>
  )
}

const ProductForm = (props) => {
  const [newProduct, setNewProduct] = useState(() => {
    const newProduct = createDefaultProduct();
    Object.assign(newProduct, props.product);
    return newProduct;
  });

  const onChangeCallback = useCallback((value, field) => {
    const p = {...newProduct };

    if (Array.isArray(value) && Array.isArray(field)) {
      for (let i = 0; i < value.length; ++i)
        p[field[i]] = value[i]
    } else {
      p[field] = value;
    }

    setNewProduct(p);
  }, [newProduct]);

  return (
    <>
    <Tab
      defaultActiveIndex={props.clipboard !== null ? 2 : 0}
      panes={[
        { menuItem: 'Details',
          render: (props) => <DetailsTab
            product={newProduct}
            onChange={onChangeCallback}
            />
        },
        { menuItem: 'Prix',
          render: (props) => <PrixTab
            product={newProduct}
            onChange={onChangeCallback}
          />
        },
        { menuItem: 'Autre',
          render: () => <AutreTab
            key={props.clipboard && props.clipboard.position}
            onClipboardChange={props.onClipboardChange}
            onClipboardDeselect={props.onClipboardDeselect}
            onClipboardCopy={props.onClipboardCopy}
            clipboard={props.clipboard}
            product={props.product}
          />
        }
      ]}
      style={{ marginBottom: 20 }}
    />
    {props.clipboard === null &&
      <Form.Group inline>
        <Button primary onClick={() => props.onChange(newProduct)}>Confirmer</Button>
        {props.product.id && (
          <Button color='red' onClick={() => props.onDelete(props.product.id)}>Supprimer</Button>
        )}
      </Form.Group>
    }
    </>
  )
}

export default ProductForm;