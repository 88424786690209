import React, { Component } from 'react';
import { Grid, Radio, Input, Button, Icon } from 'semantic-ui-react';

class ScheduleEditor extends Component {
    constructor(props) {
        super(props);
        this.hoursSave = {};
    }

    onHourInputChange(dayIndex, hoursIndex, hourIndex, e) {
        let hours = [...this.props.schedule[dayIndex]];
        let newCreneau = hours[hoursIndex].split('-');
        newCreneau[hourIndex] = e.target.value;
        hours[hoursIndex] = newCreneau.join('-');
        this.props.onChange(dayIndex, hours);
    }

    handleAddHours(dayIndex) {
        let hours = [...this.props.schedule[dayIndex], '8:00-17:00'];
        this.props.onChange(dayIndex, hours);

    }

    handleRemoveHours(dayIndex, hoursIndex) {
        let hours = [...this.props.schedule[dayIndex]];
        hours.splice(hoursIndex, 1);
        this.props.onChange(dayIndex, hours);

    }

    handleDayToggle(dayIndex, e, data) {
        const checked = data.checked;
        if(!checked) {
            this.hoursSave[dayIndex] = [...this.props.schedule[dayIndex]];
            this.props.onChange(dayIndex, []);
        }
        else {
            let hours = this.hoursSave[dayIndex] || ['8:00-17:00'];
            this.props.onChange(dayIndex, hours);
        }

    }

    renderHourInputs(dayIndex) {
        if(!this.props.schedule[dayIndex] || this.props.schedule[dayIndex].length === 0)
            return <span>Fermé</span>

        return this.props.schedule[dayIndex].map((e, i) => {
            const hours = e.split('-');
            return (
                <React.Fragment>
                    <Grid.Column>
                        <Input type='time' onChange={this.onHourInputChange.bind(this, dayIndex, i, 0)} value={hours[0]} />
                        -
                        <Input type='time' onChange={this.onHourInputChange.bind(this, dayIndex, i, 1)}value={hours[1]} />
                        {this.props.schedule[dayIndex].length > 0 &&
                            <Button onClick={this.handleRemoveHours.bind(this, dayIndex, i)} icon><Icon name='close'/></Button>
                        }
                    </Grid.Column>
                </React.Fragment>
            );
        });
    }

    jours = [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
    ]

    render() {
        return(
            <Grid>
                {this.jours.map((day, dayIndex) =>
                <Grid.Row key={day}>
                    <Grid.Column width={4}>
                        <Radio
                            toggle
                            onChange={this.handleDayToggle.bind(this, dayIndex)}
                            label={day}
                            checked={this.props.schedule[dayIndex] && this.props.schedule[dayIndex].length > 0}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} textAlign='center'>
                        {this.renderHourInputs(dayIndex)}
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button onClick={this.handleAddHours.bind(this, dayIndex)}>Ajouter un créneau</Button>
                    </Grid.Column>
                </Grid.Row>
                )}
            </Grid>
        )
    }
}

export default ScheduleEditor;