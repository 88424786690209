import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Menu, Button, Form, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import XLSX from 'xlsx';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

const getDates = (date) => {
  const startDate = new Date(date);
  startDate.setDate(1);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
  endDate.setTime(endDate.getTime() - 1);

  return [startDate, endDate];
}

const getMonthData = async (restoId, date) => {
  const [startDate, endDate] = getDates(date);

  const docs = await firebase.firestore().collection('Restaurant').doc(restoId).collection('z')
    .where('startAt', '>=', startDate)
    .where('startAt', '<', endDate)
    .get();

  if (!docs.empty) {
    return docs.docs.map(d => d.data());
  }
  else
    return null;
}

const makeSheet = (data, resto = {}, date) => {
  if (!data) return
  const [startDate, endDate] = getDates(date);
  const workbook = XLSX.utils.book_new();

  const aoa = [
    [resto.name || '', resto.postal || ''],
    ['Periode', startDate.toLocaleDateString('fr-FR'), endDate.toLocaleDateString('fr-FR')],
    ['CLOTURATION', 'DATE', 'MONTANT', 'CA 5.5', 'CA 10', 'CA 20', 'ESP', 'CB', 'TICKET', 'CHEQUE', 'CHQ VAC', 'AVOIR', 'AUTRE', 'AUTRE2', 'AUTRE3', 'TP'],
  ];

  let i = 0
  for (; i < data.length; ++i) {
    const z = data[i];
    const payments = new Array(9).fill(0);
    let totalPayment = 0;

    const finishedOrder = Object.keys(z.finishedOrder);
    for (let ii = 0; ii < finishedOrder.length; ++ii) {
      const order = z.finishedOrder[finishedOrder[ii]];

      const orderPayments = Object.values(order.payments)
      for (let iii = 0; iii < orderPayments.length; ++iii) {
        const payment = orderPayments[iii];
        payments[payment.id] += payment.amount;
        totalPayment += payment.amount;
      }
    }

    const tp = totalPayment - z.totalTtc;

    aoa.push([
      z.id,
      z.startAt.toDate().toLocaleDateString('fr-FR'),
      {f: `ROUND(${z.totalTtc}, 2)`},
      {f: `ROUND(${z.totalTtc1}, 2)`},
      {f: `ROUND(${z.totalTtc2}, 2)`},
      {f: `ROUND(${z.totalTtc3}, 2)`},
      ...(payments.map(p => ({f: `ROUND(${p}, 2)`}))),
      {f: `ROUND(${tp}, 2)`},
    ])
  }

  aoa.push([
    'TOTAL',
    '',
    {f: `SUM(C4:C${i + 3})`},
    {f: `SUM(D4:D${i + 3})`},
    {f: `SUM(E4:E${i + 3})`},
    {f: `SUM(F4:F${i + 3})`},
    {f: `SUM(G4:G${i + 3})`},
    {f: `SUM(H4:H${i + 3})`},
    {f: `SUM(I4:I${i + 3})`},
    {f: `SUM(J4:J${i + 3})`},
    {f: `SUM(K4:K${i + 3})`},
    {f: `SUM(L4:L${i + 3})`},
    {f: `SUM(M4:M${i + 3})`},
    {f: `SUM(N4:N${i + 3})`},
    {f: `SUM(O4:O${i + 3})`},
    {f: `SUM(P4:P${i + 3})`},
  ]);

  const filename = `${resto.name} - Journal de vente ${startDate.toLocaleDateString('fr-FR').replaceAll('/', '-')} au ${endDate.toLocaleDateString('fr-FR').replaceAll('/', '-')}`;

  const sheet = XLSX.utils.aoa_to_sheet(aoa);
  XLSX.utils.book_append_sheet(workbook, sheet, 'Journal de vente');

  return XLSX.writeFile(workbook, filename + '.xlsx');
}

const ExportExcelMonth = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [comptaOption, setComptaOption] = useState(false);
  const [statOption, setStatOption] = useState(false); // a changer

  const showCallback = useCallback(() => setShowModal(true), []);
  const hideCallback = useCallback(() => setShowModal(false), []);
  const makeSheetCallback = useCallback(() => {
    if (data !== null) {
      makeSheet(data, props.resto, props.date, comptaOption, statOption);
    }
    else {
      if (comptaOption) {
        setLoading(true);
        getMonthData(props.resto.id, props.date).then(d => {
          setData(d);
          makeSheet(d, props.resto, props.date, comptaOption, statOption);
          setLoading(false);
        });
      }
      else {
        makeSheet(null, props.resto, props.date, comptaOption, statOption);
      }
    }
  }, [props, data, comptaOption, statOption]);

  useEffect(() => setData(null), [props]);

  return (
    <>
    <Menu.Menu position='right'>
      <Menu.Item
        onClick={showCallback}
      >
        Exporter en Excel
      </Menu.Item>
    </Menu.Menu>
    <Modal open={showModal} onClose={hideCallback}>
      <Modal.Header>
        Selectionner les données à exporter
      </Modal.Header>

      <Modal.Content>
        <Form onSubmit={null}>
          <Form.Field>
            <Checkbox checked={comptaOption} onChange={() => setComptaOption(!comptaOption)} label='Données comptables' />
          </Form.Field>
          <Form.Field>
            <Checkbox disabled checked={statOption} onChange={() => setStatOption(!statOption)} label='Statistiques' />
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={hideCallback}>
          Annuler
        </Button>
        <Button disabled={loading} loading={loading} primary onClick={makeSheetCallback}>
          Exporter
        </Button>
      </Modal.Actions>
    </Modal>
    </>
  );
}

export default connect(
  (state) => ({
    resto: state.resto.currentResto,
  })
)(ExportExcelMonth);