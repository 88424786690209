import { useEffect, useMemo, useState } from 'react';

const useUserStats = (userStats) => {
  const stats = useMemo(() => {
    if (!userStats || userStats.length === 0) {
      return {
        amount: 0,
        count: 0,
      }
    }

    else if (userStats.length > 0) {
      const code30 = { amount: 0, count: 0 }
      const tracer30 = userStats.filter((t) => t.code === 30)
      tracer30.forEach((element) => {
        const details = JSON.parse(element.details)
        code30.amount += details?.amount || 0
        code30.count += 1
      })

      return {
        amount: code30.amount,
        count: code30.count
      }
    }
  }, [userStats]);
  return stats;
};

export default useUserStats;