import React, { useState } from 'react';
import { Modal, Form, Button } from 'semantic-ui-react'

const AddRestoModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [postal, setPostal] = useState('');

  return (
    <Modal
      onClose={props.onClose}
      open={props.user !== null}
    >
      <Modal.Header>Ajouter un resto pour {props.user.email}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={null}>
          <Form.Input
            label='License du restaurant'
            value={id}
            onChange={(e, { value }) => setId(value)}
          />
          <Form.Input
            label='Nom du restaurant'
            value={name}
            onChange={(e, { value }) => setName(value)}
          />
          <Form.Input
            label='Code postal du restaurant'
            value={postal}
            onChange={(e, { value }) => setPostal(value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose}>Annuler</Button>
        <Button
          primary
          disabled={loading}
          loading={loading}
          onClick={() => {
            setLoading(true);
            props.onSubmit({ id, name, postal })
          }}
        >Ajouter</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AddRestoModal;