import React from 'react';

/**
 * Componsant pour la vague dans la sidebar et dans la page de login
 */
const WaveSVG = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" style={{ display: 'block' }} {...props}>
      <path fill="#FFFFFF" fillOpacity="0.2" d="M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"></path>
      <path fill="#FFFFFF" fillOpacity="0.2" d="M-0.56,68.58 C149.99,150.00 294.58,-39.95 501.69,64.63 L500.00,150.00 L0.00,150.00 Z"></path>
      <path fill="#FFFFFF" fillOpacity="0.2" d="M-1.12,84.38 C149.99,150.00 292.89,-28.13 501.69,80.42 L500.00,150.00 L0.00,150.00 Z"></path>
      <path fill="#FFFFFF" fillOpacity="1" d="M-0.56,98.19 C146.72,162.33 292.89,-28.13 501.69,96.20 L500.00,150.00 L0.00,150.00 Z"></path>
    </svg>
  )
}

export default WaveSVG;