import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import * as Pages from '../pages';

const AppRouter = () =>
  <Switch>
    <Route exact path='/' component={Pages.LoginPage} />
    <PrivateRoute exact path='/temps-reel' component={Pages.RealTime} />
    <PrivateRoute exact path='/rapport-z' component={Pages.RapportZ} />
    <PrivateRoute exact path='/deconnexion' component={Pages.LogoutPage} />
    <PrivateRoute exact path='/reporting' component={Pages.Reporting} />
    <PrivateRoute exact path='/reservations' component={Pages.Reservation} />
    <PrivateRoute exact path='/carte' component={Pages.Carte} admin />
    <PrivateRoute exact path='/comptes' component={Pages.AccountCreation} admin />
    <Route render={() => <Redirect to={{ pathname: '/' }} />} />
  </Switch>

export default AppRouter;