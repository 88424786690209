import React from 'react';

import { Grid } from 'semantic-ui-react';
import ProductForm from './ProductForm';
import SubCategoryForm from './SubCategoryForm';
import CategoryForm from './CategoryForm';
/**
 *
 * @param type 'category' || 'subcategory' || 'product'
 * @param selectedItem Category || SubCategory || Product
 * @param onChange (item: Category || SubCategory || Product) => void
 */
const LeftCarte = (props) => {
  return (
    <Grid.Column width={6}>
    {props.type === 'product' ? (
      <ProductForm
        key={'pos' + props.selectedItem.position + 'id' + (props.selectedItem.id ? props.selectedItem.id : '')}
        product={props.selectedItem}
        clipboard={props.clipboard}
        onChange={props.onChange}
        onDelete={props.onDelete}
        onClipboardChange={props.onClipboardChange}
        onClipboardCopy={props.onClipboardCopy}
        onClipboardDeselect={props.onClipboardDeselect}
      />
    ) : props.type === 'subcategory' ? (
      <SubCategoryForm
        key={props.selectedItem.position}
        subCategory={props.selectedItem}
        onChange={props.onChange}
        onDelete={props.onDelete}
      />
    ) : props.type === 'category' ? (
      <CategoryForm
        key={props.selectedItem.id}
        category={props.selectedItem}
        onChange={props.onChange}
        onDelete={props.onDelete}
      />
    ) : 'Erreur'}
    </Grid.Column>
  );
}

export default LeftCarte;