import React from 'react';
import { Grid } from 'semantic-ui-react';

const CategoryPicker = (props) => {
  const categoryCells = []; // contains category divs for rendering
  const subCategoryCells = []; // contains sub category divs for rendering

  for (let i = 0; i < 8; ++i) {

    // render category cells
    categoryCells.push((
      <div
        key={'category'+i}
        className='carte-cell category rightmost'
        style={props.selectedCategory.id === props.categories[i].id ? ({
          backgroundColor: props.categories[i].color + 'C8'
        }) : ({
          backgroundColor: '#FFF',
          color: 'black'
        })}
        onClick={() => props.onCategoryChange(props.categories[i])}
      >
        {props.categories[i].name ? (
          <>
          <img
            src={'./category/' + props.categories[i].logo + '.png'}
            alt='Icone catégorie'
            style={{ height: 30, width: 30 }}
          />
          <span>{props.categories[i].name}</span>
          </>
        ) : (
          <span>+</span>
        )}
      </div>
    ));

    // render subcategory cells
    const findSubCategory = props.subCategories.find(e => (
      props.selectedCategory.id === e.categoryId
      && e.position === i + 1
    )) || { position: i + 1 };

    const isSubCategorySelected = props.selectedSubCategory.position === findSubCategory.position;

    subCategoryCells.push((
      <div
        key={'subcategory'+i}
        className='carte-cell category'
        style={isSubCategorySelected ? ({
          backgroundColor: props.selectedCategory.color + 'C8'
        }) : ({
          backgroundColor: props.selectedCategory.color + '64',
          color: props.selectedCategory.color
        })}
        onClick={() => props.onSubCategoryChange(findSubCategory)}
      >
        {findSubCategory.name ? (
          <span>{findSubCategory.name}</span>
        ) : (
          <span style={{ color: 'black' }}>+</span>
        )}
      </div>
    ));
  }

  return (
    <>
    <Grid.Column width={2} style={{ padding: 0 }}>
      {subCategoryCells}
    </Grid.Column>
    <Grid.Column width={2} style={{ padding: 0 }}>
      {categoryCells}
    </Grid.Column>
    </>
  )
}

export default CategoryPicker;