import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { useTable, useSortBy } from 'react-table';


const MeilleursVentesChart = (props) => {
  const propsData = props.data;
  const data = React.useMemo(() => propsData, [propsData]);

  const columns = React.useMemo(() => [
    {
      Header: 'Description',
      accessor: 'name',
      sortDescFirst: true,
    },
    {
      Header: 'Quantité',
      accessor: 'quantity',
      sortDescFirst: true,
      textAlign: 'center'
    },
    {
      Header: 'Dont offert',
      accessor: 'freeQuantity',
      sortDescFirst: true,
      textAlign: 'center'
    },
    {
      Header: 'Montant TTC',
      accessor: 'totalTtc',
      sortDescFirst: true,
      Cell: ({ value }) => value.toFixed(2),
      textAlign: 'right'
    },
  ], []);

  const tableInstance = useTable({ columns, data, disableSortRemove: true, initialState: { sortBy: [ { id: 'quantity', desc: true } ]} }, useSortBy);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className='table-height'>
    <Table {...getTableProps()} unstackable>
      <Table.Header>
        {headerGroups.map(h => (
          <Table.Row {...h.getHeaderGroupProps()}>
            {h.headers.map(column => (
              <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}  textAlign={column.textAlign}>
                {column.render('Header')}
                {column.isSorted ? ( column.isSortedDesc ? <Icon name='sort down' /> : <Icon name='sort up' />) : <Icon /> }
              </Table.HeaderCell>
            ))}
          </Table.Row>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {props.data.length > 0 ? rows.map(row => {
          prepareRow(row);
          return (
            <Table.Row {...row.getRowProps()}>
              {row.cells.map(cell => (
                <Table.Cell {...cell.getCellProps()} textAlign={cell.column.textAlign}>{cell.render('Cell')}</Table.Cell>
              ))}
            </Table.Row>
          )
        }) : <Table.Cell colSpan='4'>Pas de données</Table.Cell>}
      </Table.Body>
    </Table>
    </div>
  );
}

export default MeilleursVentesChart;