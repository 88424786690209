import React from 'react';
import { Table } from 'semantic-ui-react';

const CATable = (props) => {
  return (
    <Table unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Total</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>HT</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalHt ? props.data.totalHt.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>TTC</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalTtc ? props.data.totalTtc.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
      </Table.Body>

      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Ventilation TVA</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>TVA {props.data.vatRate1}%</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalHt1 ? props.data.totalHt1.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>TVA {props.data.vatRate2}%</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalHt2 ? props.data.totalHt2.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>TVA {props.data.vatRate3}%</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalHt3 ? props.data.totalHt3.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
      </Table.Body>

      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>TVA collectée</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>TVA {props.data.vatRate1}%</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalVat1 ? props.data.totalVat1.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>TVA {props.data.vatRate2}%</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalVat2 ? props.data.totalVat2.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>TVA {props.data.vatRate3}%</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalVat3 ? props.data.totalVat3.toFixed(2)  : '0.00'}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

export default CATable;