import React, { useState } from 'react';
import { Table, Modal, Button, Icon } from 'semantic-ui-react';

const getTypeString = (type) => {
  switch (type) {
    case 'bar': return 'Commande';
    case 'sit': return 'Table';
    case 'takeAway': return 'Emporté';
    case 'delivery': return 'Livraison';
    default: return '';
  }
}

const vatIdToString = (id) => {
  switch (id) {
    case 1: return 'A';
    case 2: return 'B';
    case 3: return 'C';
    default: return '';
  }
}

const dateOptions = {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
};

const timeOptions = {
  hour: '2-digit',
  minute: '2-digit',
}

/**
 * Tableau qui affiche les tickets en props
 * @param data: [Ticket]
 */
const OrdersTable = (props) => {
  const [clickedTicket, setClickedTicket] = useState(null);

  return (
    <div className='table-height'>

      {/* Tableau de tickets */}
      <Table unstackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Heure</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Table</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Montant</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Serveur</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Voir</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(props.data && props.data.length > 0) ? props.data.map(t => (
            <Table.Row style={t.canceled ? { backgroundColor: '#D0D0D0', cursor: 'pointer' } : { cursor: 'pointer' }}>
              <Table.Cell>{t.createdAt.toDate().toLocaleTimeString('fr-FR', timeOptions)}</Table.Cell>
              <Table.Cell textAlign='center'>{t.table || "/"}</Table.Cell>
              <Table.Cell textAlign='right'>{t.totalTtc.toFixed(2) || "/"}</Table.Cell>
              <Table.Cell textAlign='right'>{t.userName || "/"}</Table.Cell>
              <Table.Cell textAlign='center'><Icon name='eye' /></Table.Cell>
            </Table.Row>
          )) : (
            <Table.Row>
              <Table.Cell colSpan='5'>Pas de données</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
}

export default OrdersTable;