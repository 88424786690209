import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Breakpoint } from 'react-socks';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import { Tab, Container } from 'semantic-ui-react';
import './Reservation.css';

import ReservationList from './ReservationList';
import ReservationSettings from './ReservationSettings';

const jours = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
]

const getReservationSettings = async (restoId) => {
  const doc = await firebase.firestore()
    .collection('Restaurant')
    .doc(restoId)
    .get()

  const settings = doc.get('reservationSettings');
  if (settings) settings.urlName = doc.get('urlName');

  return settings;
}

const Reservation = (props) => {
  const [reservations, setReservations] = useState([]);
  const [reservationSettings, setReservationSettings] = useState(null);

  // get reservations
  useEffect(() => {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    const unsubscribe = firebase.firestore()
      .collection('Restaurant')
      .doc(props.resto.id)
      .collection('Reservation')
      .where('reservationDate', '>=', startDate)
      .onSnapshot(snap => {
        const reservations = snap.docs.map(doc => {
          const data = doc.data()
          return {
            id: doc.id,
            ...data,
            reservationDate: data.reservationDate.toDate(),
          };
        });
        setReservations(reservations);
      });

    return unsubscribe;
  }, []);

  // get reservation settings
  useEffect(() => {
    getReservationSettings(props.resto.id).then(data => {
      setReservationSettings(data);
    });
  }, []);


  return (
    <Container>
      <Breakpoint small down style={{ position: 'absolute', top: '22px', left: '70px' }}>
        <h3>Réservations</h3>
      </Breakpoint>
      <Tab
        menu={{}}
        panes={[
          {
            menuItem: 'Liste des réservations',
            render: () => (
              <ReservationList
                reservations={reservations}
                resto={props.resto}
              />
            )
          },
          {
            menuItem: 'Réglage',
            render: () => (
              <ReservationSettings
                reservationSettings={reservationSettings}
                resto={props.resto}
              />
            )
          }
        ]}
      />
    </Container>
  );
}

export default connect(
  (state) => ({
    resto: state.resto.currentResto,
  }),
)(Reservation);
