import { useEffect, useMemo, useState } from 'react';

function getStats(zArray) {
  let totalTableTtc = 0.00
  let totalDeliveryTtc = 0.00
  let totalTakeAwayTtc = 0.00
  let totalBarTtc = 0.00
  let totalTable = 0
  let totalDelivery = 0
  let totalBar = 0
  let totalPerson = 0
  let totalTakeAway = 0
  let totalTtc = 0
  let totalHt = 0
  let totalHt1 = 0
  let totalHt2 = 0
  let totalHt3 = 0
  let totalTtc1 = 0
  let totalTtc2 = 0
  let totalTtc3 = 0
  let totalVat1 = 0
  let totalVat2 = 0
  let totalVat3 = 0
  let vatRate1 = 0
  let vatRate2 = 0
  let vatRate3 = 0
  let ESP = 0
  let CB = 0
  let TR = 0
  let CQ = 0
  let CV = 0
  let Autre = 0
  let Credit = 0
  let Autre2 = 0
  let Autre3 = 0
  let totalTtcCurrent = 0.00
  let totalCredit = 0
  let totalCode30 = 0

  for (let i = 0; i < zArray.length; i++) {
    const z = zArray[i];

    vatRate1 = z.vatRate1;
    vatRate2 = z.vatRate2;
    vatRate3 = z.vatRate3;
    if (z.canceled) continue;
    totalTtc += z.totalTtc;
    totalHt1 += z.totalHt1;
    totalHt2 += z.totalHt2;
    totalHt3 += z.totalHt3;
    totalHt = totalHt1 + totalHt2 + totalHt3;
    totalVat1 += z.totalTtc1 - z.totalHt1;
    totalVat2 += z.totalTtc2 - z.totalHt2;
    totalVat3 += z.totalTtc3 - z.totalHt3;
    totalTtc1 += z.totalTtc1;
    totalTtc2 += z.totalTtc2;
    totalTtc3 += z.totalTtc3;

    ESP += z.totalPayment1
    CB += z.totalPayment2
    TR += z.totalPayment3
    CQ += z.totalPayment4
    CV += z.totalPayment5
    Autre += z.totalPayment6
    Credit += z.totalPayment7
    Autre2 += z.totalPayment8
    Autre3 += z.totalPayment9

    if (z.paybackCash !== null && z.paybackCash > 0)
      ESP -= z.paybackCash;

    totalPerson += z.totalPerson;
    totalTable += z.totalTable;
    totalDelivery += z.totalDelivery;
    totalBar += z.totalBar;
    totalTakeAway += z.totalTakeAway

    totalTableTtc += z.totalTableTtc
    totalDeliveryTtc += z.totalDeliveryTtc;
    totalBarTtc += z.totalBarTtc;
    totalTakeAwayTtc += z.totalTakeAwayTtc;

    const details = z.userStat ?? {}
    totalCode30 += details?.code30?.amount ?? 0

  }

  return {
    payment: {
      totalPayment1: ESP,
      totalPayment2: CB,
      totalPayment3: TR,
      totalPayment4: CQ,
      totalPayment5: CV,
      totalPayment6: Autre,
      totalPayment7: Credit,
      totalPayment8: Autre2,
      totalPayment9: Autre3,
      labels: [
        'Espèce',
        'Carte bleu',
        'Ticket restaurant',
        'Chèque',
        'Chèque vacance',
        'Autre',
        'Autre 2',
        'Autre 3',
      ],
      datasets: [{
        data: [ESP.toFixed(2), CB.toFixed(2), TR.toFixed(2), CQ.toFixed(2), CV.toFixed(2), Autre.toFixed(2), Autre2.toFixed(2), Autre3.toFixed(2)],
        backgroundColor: ['#f08344', '#1774b1', '#55c2fc', '#e11b65', '#c969bc', '#8c2f0e', '#43c2ae', '#b89d4e']
      }]
    },
    turnover: {
      totalTtc,
      totalHt,
      totalHt1,
      totalHt2,
      totalHt3,
      totalVat1,
      totalVat2,
      totalVat3,
      totalTtc1,
      totalTtc2,
      totalTtc3,
      vatRate1,
      vatRate2,
      vatRate3,
    },
    statistic: {
      totalTableTtc,
      totalDeliveryTtc,
      totalTakeAwayTtc,
      totalBarTtc,
      totalTable,
      totalDelivery,
      totalBar,
      totalPerson,
      totalTakeAway,
      averageCart: totalTtc / (totalDelivery + totalBar + totalTakeAway + totalTable),
      averagePerson: totalTableTtc / totalPerson,
      totalCode30
    }
  }
}
function getProductStats(finishedOrders) {
  const productStatsDay = []
  let totalTTC = 0
  for (let j = 0; j < finishedOrders.length; j++) {
    if (finishedOrders[j].canceled === false) {
      const products = Object.values(finishedOrders[j].products)
      for (let k = 0; k < products.length; k++) {
        const cartProduct = products[k]
        if (cartProduct.type === 'option' && cartProduct.priceTtc === 0) continue
        if (cartProduct.type === 'comment' || cartProduct.type === 'next') continue
        const p = productStatsDay.filter(product => product.name === cartProduct.name)[0]
        if (p) {
          if (cartProduct.totalTtc === 0) p.freeQuantity += cartProduct.quantity
          p.quantity += (cartProduct.gram === true ? 1 : cartProduct.quantity)
          p.totalTtc += cartProduct.totalTtc
          totalTTC += cartProduct.totalTtc
          if (cartProduct.totalTtc !== 0)
            p.totalHt += cartProduct.priceHt * (cartProduct.gram === true ? cartProduct.quantity / 100 : cartProduct.quantity)
        } else {
          totalTTC += cartProduct.totalTtc === 0 ? 0 : (cartProduct.priceTtc * (cartProduct.gram === true ? cartProduct.quantity / 100 : cartProduct.quantity))
          productStatsDay.push({
            name: cartProduct.name,
            quantity: (cartProduct.gram === true ? 1 : cartProduct.quantity),
            freeQuantity: cartProduct.totalTtc === 0 ? cartProduct.quantity : 0,
            totalTtc: cartProduct.totalTtc === 0 ? 0 : (cartProduct.priceTtc * (cartProduct.gram === true ? cartProduct.quantity / 100 : cartProduct.quantity)),
            totalHt: cartProduct.totalTtc === 0 ? 0 : (cartProduct.priceHt * (cartProduct.gram === true ? cartProduct.quantity / 100 : cartProduct.quantity)),
          })
        }
      }
    }
  }

  productStatsDay.sort((a, b) => b.quantity - a.quantity)
  return productStatsDay;
}

const useZStats = (zArray) => {
  const stats = useMemo(() => {
    if (!zArray || zArray.length === 0) {
      return null
    }

    else if (zArray.length > 0) {
      const data = getStats(zArray);
      return data;
    }
  }, [zArray]);
  return stats;
};

export default useZStats;