import React from 'react';
import { Form, Button, Header } from 'semantic-ui-react';

const ForgottenPasswordForm = (props) => {
  return (
  <>
    <Header as='h3'>Envoyer un nouveau mot de passe à votre adresse email</Header>
    <Form.Input
      fluid
      icon='user'
      iconPosition='left'
      label='Votre adresse email'
      name='email'
      value={props.email}
      onChange={(e) => props.setEmail(e.target.value)}
    />
    <Button color='yellow' fluid size='large' onClick={props.onSubmit} loading={props.loading} disabled={props.email === '' || props.password === ''}>
      Envoyer
    </Button>
    <Button basic fluid onClick={() => props.setForgottenToggle(false)} style={{ boxShadow: 'unset'}}>Retour</Button>
  </>
  );
}

export default ForgottenPasswordForm;