import React from 'react';

import { Grid } from 'semantic-ui-react';

const ProductPicker = (props) => {
  const productCell = [];

  for (let i = 0; i < 24; ++i) {
    const findProduct = props.products.find(p => (
      p.categoryId === props.selectedCategory.id &&
      p.subCategoryId === props.selectedSubCategory.id &&
      p.position === i + 1
    )) || {
      position: i + 1,
      color: props.selectedCategory.color,
      categoryId: props.selectedCategory.id,
      subCategoryId: props.selectedSubCategory.id
    };

    const isProductSelected = props.selectedProduct && (findProduct.position === props.selectedProduct.position);
    const isProductClipboarded = props.clipboard && props.clipboard.id === findProduct.id;

    productCell.push((
      <Grid.Column key={`product-${props.selectedCategory.id}-${props.selectedSubCategory.id}-${findProduct.position}`} style={{ padding: '0 1px'}}>
        <div
          className='carte-cell'
          style={{
            backgroundColor: findProduct.id ? findProduct.color : findProduct.color+ '16',
            border: (isProductSelected && !isProductClipboarded ? '2px solid red' :
              isProductClipboarded && !isProductSelected ? '3px dotted red' :
              isProductSelected && isProductClipboarded ? '3px dashed red' : '2px solid rgba(0, 0, 0, 0)'),
          }}
          onClick={
            (props.selectedSubCategory.name ?
              () => props.onProductChange(findProduct)
              :
              null
            )
          }
        >
          {findProduct.type === 'option' && (
            <img style={{ position: 'absolute', top: 2, left: 3, width: 25 }} alt='Option Icone' src='./category/option.png' />
          )}
          {findProduct.gram && (
            <img style={{ position: 'absolute', top: 2, right: 0, width: 25 }} alt='Grammage Icone' src='./category/grams.png' />
          )}
          {findProduct.manualPrice && (
            <img style={{ position: 'absolute', bottom: 2, right: 0, width: 25 }} alt='Manuel Icone' src='./category/manual.png' />
          )}
          {findProduct.name || '+'} <br/>
          {findProduct.priceTtc ? findProduct.priceTtc.toFixed(2) + '€' : ''}
        </div>
      </Grid.Column>
    ));
  }

  return (
    <Grid.Column width={12}>
      <Grid columns={3} style={{ backgroundColor: props.selectedCategory.color + '32'}}>
        {productCell}
      </Grid>
    </Grid.Column>
  );
}

export default ProductPicker;