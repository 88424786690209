import React, { useState, useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import { Grid, Button, Form, Label } from 'semantic-ui-react';
import { CirclePicker } from 'react-color';
import ScheduleEditor from './ScheduleEditor';

const jours = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
]

const updateSchedule = (restoId, schedule) => {
  firebase.firestore()
    .collection('Restaurant')
    .doc(restoId)
    .update({
      'reservationSettings.schedule': schedule
    });
}

const updateSettings = (restoId, settings) => {
  const { urlName, ...reservationSettings } = settings;
  const payload = {};
  Object.keys(reservationSettings).forEach(k => {
    payload[`reservationSettings.${k}`] = reservationSettings[k];
  });
  payload.urlName = urlName;

  firebase.firestore()
    .collection('Restaurant')
    .doc(restoId)
    .update(payload);
}

const ReservationSettings = (props) => {
  const [isEditingSchedule, setIsEditingSchedule] = useState(false);
  const [schedule, setSchedule] = useState(props.reservationSettings ? props.reservationSettings.schedule : {});
  const [otherSettings, setOtherSettings] = useState(() => {
    if (props.reservationSettings) {
      const { schedule, ...otherSettings } = props.reservationSettings;
      return otherSettings || {};
    }
    return {}
  });

  const onScheduleConfirm = useCallback(() => {
    updateSchedule(props.resto.id, schedule);
    setIsEditingSchedule(false);
  }, [props.resto.id, schedule]);

  const onSettingsConfirm = useCallback((e) => {
    e.preventDefault();
    updateSettings(props.resto.id, otherSettings);
  })

  const onInputChange = useCallback((e, { value }) => {
    setOtherSettings({ ...otherSettings, [e.target.name]: value });
  }, [otherSettings]);

  return (
    <Grid padded>
      <Grid.Row><h2>Horaires de réservations</h2></Grid.Row>

      {isEditingSchedule ? (
        <>
          <Grid.Row>
            <Grid.Column width='16'>
              <ScheduleEditor
                onChange={(dayIndex, daySchedule) => {
                  const newSchedule = { ...schedule };
                  newSchedule[dayIndex] = daySchedule;
                  setSchedule(newSchedule);
                }}
                schedule={schedule || {}}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Button onClick={() => setIsEditingSchedule(false)}>
              Annuler
            </Button>
            <Button primary onClick={onScheduleConfirm}>
              Valider
            </Button>
          </Grid.Row>
        </>
      ) : (
        <>
          {jours.map((day, i) =>
            <Grid.Row key={i}>
              <Grid.Column width={8}>{day}</Grid.Column>
              <Grid.Column width={8}>{schedule[i] && schedule[i].length > 0 ? schedule[i].map(s => <div key={s}>{s} </div>) : <span>Fermé</span>}</Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Button onClick={() => setIsEditingSchedule(true)}>Modifier</Button>
          </Grid.Row>
        </>
      )}

      <Grid.Row><h2>Réglages du formulaire de réservation</h2></Grid.Row>

      <Form onSubmit={onSettingsConfirm}>
        <Form.Input
          label='Nom du restaurant'
          value={otherSettings.restoName || ''}
          name='restoName'
          onChange={onInputChange}
        />
        <Form.Input
          label='Email'
          value={otherSettings.email || ''}
          name='email'
          onChange={onInputChange}
        />
        <Form.Input
          label='Téléphone'
          value={otherSettings.phone || ''}
          name='phone'
          onChange={onInputChange}
        />
        <Form.Group>
          <Form.Input
            label='URL de la page de réservation'
            value={otherSettings.urlName || ''}
            name='urlName'
            onChange={onInputChange}
            labelPosition='left'
          >
            <Label>https://reservation.easoft.pro/</Label>
            <input />
          </Form.Input>
        </Form.Group>
        <Form.TextArea
          label='Message de confirmation'
          value={otherSettings.confirmationMessage || ''}
          name='confirmationMessage'
          onChange={onInputChange}
        />
        <Form.Input
          label='URL du site web du restaurant'
          value={otherSettings.redirectUrl || ''}
          name='redirectUrl'
          onChange={onInputChange}
        />

        <Form.Group>
          <div className='field'>
            <label>Couleur</label>
            <CirclePicker
              color={otherSettings.color || '#00ccbb'}
              onChange={(color) => onInputChange({ target: { name: 'color' } }, { value: color.hex })}
              circleSize={40}
              colors={["#000000", "#424242", "#0F9D58", "#00ccbb", "#00838f", "#3b5998", "#0277bd", "#4285F4", "#b71c1c", "#d81b60", "#9E0661", "#DB4437", "#e65100", "#fbc02d"]}
              width='420px'
            />
          </div>
        </Form.Group>

        <Form.Button
          primary
          type='submit'
        >Sauvegarder</Form.Button>
      </Form>
    </Grid>
  );
}

export default ReservationSettings;
