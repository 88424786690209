import React from 'react';

import { Form, Button, Checkbox } from 'semantic-ui-react';

const LoginForm = (props) => {
  return (
    <>
      <Form.Input
        fluid
        icon='user'
        iconPosition='left'
        label='Votre adresse email'
        name='email'
        value={props.email}
        onChange={(e) => props.setEmail(e.target.value)}
      />
      <Form.Input
        fluid
        icon='lock'
        iconPosition='left'
        label='Mot de passe'
        type='password'
        name='password'
        value={props.password}
        onChange={(e) => props.setPassword(e.target.value)}
      />
      <Form.Field>
        <Button color='yellow' fluid size='large' onClick={props.onSubmit} loading={props.loading} disabled={props.email === '' || props.password === ''}>
          Se connecter
        </Button>
      </Form.Field>
      <Form.Field>
        <Checkbox checked={props.remember} onClick={() => props.setRemember(!props.remember)} label='Se souvenir de moi' />
      </Form.Field>
      <Button basic fluid onClick={() => props.setForgottenToggle(true)} style={{ boxShadow: 'unset'}}>Mot de passe oublié?</Button>
    </>
  );
}

export default LoginForm;