import React, { useState } from 'react';
import { Modal, Table, Button, Checkbox } from 'semantic-ui-react'

const RemoveRestoModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [removeRestos, setRemoveRestos] = useState(() => {
    return new Array(props.user.resto.length).fill(false);
  });

  return (
    <Modal
      onClose={props.onClose}
      open={props.user !== null}
    >
      <Modal.Header>Supprimer un resto pour {props.user.email}</Modal.Header>
      <Modal.Content>
        <Table unstackable>
          <Table.Header>
            <Table.HeaderCell>License</Table.HeaderCell>
            <Table.HeaderCell>Nom</Table.HeaderCell>
            <Table.HeaderCell>Code postal</Table.HeaderCell>
            <Table.HeaderCell>Supprimer</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {props.user.resto.map((r, i) => (
              <Table.Row>
                <Table.Cell>{r.id}</Table.Cell>
                <Table.Cell>{r.name}</Table.Cell>
                <Table.Cell>{r.postal}</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={removeRestos[i]}
                    onChange={() => {
                      const newArray = [...removeRestos];
                      newArray[i] = !newArray[i];
                      console.log(newArray);
                      setRemoveRestos(newArray);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose}>Fermer</Button>
        <Button
          primary
          disabled={loading}
          loading={loading}
          onClick={() => {
          setLoading(true);
          const restos = [];
          for (let i = 0; i < removeRestos.length; ++i) {
            if (removeRestos[i])
              restos.push(props.user.resto[i]);
          }
          props.onSubmit(restos);
        }}>Confirmer</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RemoveRestoModal;