import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';

const CategoryIconPopup = (props) => {
  const icons = [];

  for (let i = 1; i <= 65; ++i) {
    icons.push((
      <div
        key={i}
        onClick={() => props.onChange(`${i}`)}
      >
        <img className='color-picker-square' src={'./category/' + i + '.png'} alt='Icone catégorie' />
      </div>
    ));
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', width: 720 }}>
      {icons}
    </div>
  )
};

const CategoryIconPicker = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popup
      open={isOpen}
      trigger={(
        <div>
          <img className='color-picker-square' src={'./category/' + props.value + '.png'} alt='Icone catégorie' />
        </div>
      )}
      content={<CategoryIconPopup onChange={(value) => {
        setIsOpen(false);
        props.onChange(value);
      }} />}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      on='click'
    />
  );
};

export default CategoryIconPicker;