import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'

import { Redirect } from 'react-router-dom';
import { Form, Grid, Header, Segment, Message } from 'semantic-ui-react';

import WaveSVG from '../../components/WaveSVG';
import LoginForm from './LoginForm';
import ForgottenPasswordForm from './ForgottenPasswordForm';

const LoginPage = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState({
    type: '', // type: 'error' || 'message'
    text: '',
  });
  const [loading, setLoading] = useState(false);
  const [forgottenToggle, setForgottenToggle] = useState(false);

  const [remember, setRemember] = useState(false);

  // Firebase login onClick callback
  const firebaseLogin = useCallback(() => {
    setLoading(true);
    firebase.auth().setPersistence(remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION)
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(user => {
        if (!user.user.emailVerified) {
          firebase.auth().signOut();
          throw new Error('Veuillez valider votre adresse email.');
        }
        return;
      })
      .catch(error => {
        setMessage({ type: 'error', text: error.message });
        setLoading(false);
      });
  }, [email, password]);

  const firebasePasswordReset = useCallback(() => {
    setLoading(true);
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      setLoading(false);
      setMessage({ type: 'success', text: 'Un email de récupération a été envoyé.' })
    }).catch((error) => {
      setLoading(false);
      setMessage({ type: 'error', text: error.message });
    });
  }, [email]);

  const toggleForm = useCallback((value) => {
    setForgottenToggle(value);
    setPassword('');
    setMessage({ type: '', message: '' });
  }, []);

  if (props.user !== null)
    return <Redirect to='/temps-reel' />;

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10px' }} className='ea-blue-bg'>
      <img src='./logo-easoft-no-bg.png' alt='Logo EASoft' />
      <Header as='h1' inverted textAlign='center'>
        Back Office
      </Header>

      <div className='login-form'>
        <Grid style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ width: 500 }}>
            <Form size='large' onSubmit={null}>
              <Segment raised style={{ padding: '20px 75px' }}>
                {!forgottenToggle ? (
                  <LoginForm
                    email={email}
                    password={password}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    loading={loading}
                    onSubmit={firebaseLogin}
                    setForgottenToggle={toggleForm}
                    remember={remember}
                    setRemember={setRemember}
                  />
                ) : (
                  <ForgottenPasswordForm
                    email={email}
                    setEmail={setEmail}
                    loading={loading}
                    onSubmit={firebasePasswordReset}
                    setForgottenToggle={toggleForm}
                  />
                )}
                {message.text ?
                  <Message negative={message.type === 'error'} positive={message.type === 'success'}>
                    <p>Erreur de connexion, veuillez ré-essayer</p>
                  </Message>
                  : null
                }
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>

      <WaveSVG style={{ position: 'fixed', bottom: 0, zIndex: '-1' }} />
    </div>
  )
}

export default connect(
  (state) => ({
    user: state.auth.user,
  })
)(LoginPage);