
import React from 'react';
import DatePicker from 'react-datepicker';
import { Menu, Icon } from 'semantic-ui-react';

/**
 * Composant interne
 */
const CustomInput = ({ value, onClick }) => (
  <Menu.Item onClick={onClick}>
    <Icon name='calendar alternate outline' color='red' />
    {value}
  </Menu.Item>
);

/**
 * Composant DatePicker
 * @props pickerType: 'month' || 'year' || null -- Change le type de calendrier selon pickerType
 * @props setDate: (Date) => void -- Fonction appelee quand la date change
 * @props date: Date -- Affichage de la date
 */
const MenuCalendar = (props) => {
  const dateFormat = props.pickerType === 'month' ? 'MMMM yyyy' :
    props.pickerType === 'year' ? 'yyyy' : 'd MMMM yyyy';

  return (
    <DatePicker
      selected={props.date}
      onChange={date => props.setDate(date)}
      customInput={<CustomInput />}
      dateFormat={dateFormat}
      showMonthYearPicker={props.pickerType === 'month'}
      showYearPicker={props.pickerType === 'year'}
      showMonthDropdown
      showYearDropdown
      dayClassName={(date) => 'calendar-day'}
      calendarClassName='calendar-pick'
    />
  );
}

export default MenuCalendar;