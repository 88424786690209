import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Menu, Container, Button, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Breakpoint } from 'react-socks';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import MenuCalendar from '../components/MenuCalendar';
import { CATable, ComparaisonN1Chart, MeilleursVentesChart, PaiementsChart, StatsTable, TicketsTable, ComparaisonPeriodeChart, StatCard } from '../components/charts';
import ExportExcelZ from '../components/ExportExcelZ';
import userZStats from '../hooks/useZStats';
import useUserStats from '../hooks/useUserStats';
import sub from 'date-fns/sub';
import Parse from 'parse/dist/parse.min.js';

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)


const totalZ = (zs) => {
  if (!zs) return 0;

  let acc = 0;

  for (let i = 0; i < zs.length; ++i) {
    acc += zs[i].totalTtc;
  }

  return acc;
}

const getDates = (date) => {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(date);
  endDate.setHours(23, 59, 59);
  return [startDate, endDate];
}

const useZ = (restoId, date) => {
  const [z, setZ] = useState(null);
  const [zN1, setZN1] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    const request2 = async () => {
      const GameScore = Parse.Object.extend(`Z_${restoId}`);
      const query = new Parse.Query(GameScore);
      const [startDate, endDate] = getDates(date);
      console.log(startDate, endDate)
      query.greaterThan('startAt', startDate)
      query.lessThan('startAt', endDate)
      const nbResult = await query.count()
      const todayZ = []
      if (nbResult > 0) {
        const result = await query.find()
        for (let i = 0; i < result.length; i++) {
          todayZ.push(result[i].attributes)
        }
        setZ(todayZ)


        const [startDate, endDate] = getDates(date);
        startDate.setDate(startDate.getDate() - 8);
        endDate.setDate(endDate.getDate() - 1);
        const query2 = new Parse.Query(GameScore);
        query2.greaterThan('startAt', startDate)
        query2.lessThan('startAt', endDate)
        const nbResult = await query2.count()
        const previousZ = []
        if (nbResult > 0) {
          const result = await query2.find()
          for (let i = 0; i < result.length; i++) {
            previousZ.push(result[i].attributes)
          }
          setZ(todayZ.concat(previousZ))

          // comparaison année précédente
          const dateN1 = new Date(date);
          dateN1.setFullYear(dateN1.getFullYear() - 1);
          const [startDateN1, endDateN1] = getDates(dateN1);
          const query3 = new Parse.Query(GameScore);
          query3.greaterThan('startAt', startDateN1)
          query3.lessThan('startAt', endDateN1)

          const resultLastYear = await query3.find()
          const lastYearZ = []

          for (let i = 0; i < resultLastYear.length; i++) {
            lastYearZ.push(resultLastYear[i].attributes)
          }
          setZN1(lastYearZ);

        }
      } else {
        setZ(null);
        setZN1(null);
      }
      setLoad(false);
    }
    request2();
  }, [restoId, date]);

  return [z, zN1, load];
}

const useUserStat = (restoId, date) => {
  const [z, setZ] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    const request = async () => {
      const GameScore = Parse.Object.extend(`UserStat_${restoId}`);
      const query = new Parse.Query(GameScore);
      const [startDate, endDate] = getDates(date);

      query.greaterThan('date', startDate)
      query.lessThan('date', endDate)
      const nbResult = await query.count()
      const todayZ = []
      if (nbResult > 0) {
        const result = await query.find()
        for (let i = 0; i < result.length; i++) {
          todayZ.push(result[i].attributes)
        }
        setZ(todayZ)
      } else {
        setZ(null);
      }
      setLoad(false);
    }
    request();
  }, [restoId, date]);

  return [z, load];
}

const RapportZ = (props) => {
  const [date, setDate] = useState(new Date());
  const [zIndex, setZIndex] = useState(0);
  const [zs, zN1, loading] = useZ(props.resto.id, date);
  const [userStat, load] = useUserStat(props.resto.id, date)


  const dateChangeCallback = useCallback((date) => {
    setDate(date);
    setZIndex(0);
  }, []);

  // sépare zs en zsToday et zsPrevious
  const [zsToday, zsPrevious] = useMemo(() => {
    if (!zs || zs.length === 0)
      return [null, null];
    else {
      const zsToday = []
      const zsPrevious = [[], [], [], [], [], [], [], []]
      for (let i = 0; i < zs.length; ++i) {
        const daysDiff = Math.round((date.getTime() - zs[i].startAt.getTime()) / 86400000); // 86400000  = nombre de milisecondes en 1 jour
        if (daysDiff <= 0)
          zsToday.push(zs[i]);
        else {
          zsPrevious[daysDiff - 1].push(zs[i]);
        }
      }
      return [zsToday, zsPrevious];
    }
  }, [zs]);

  const [selectedZ, allZ] = useMemo(() => {
    if (!zsToday || zsToday.length === 0)
      return [null, null];

    let all = [];
    for (let i = 0; i < zsToday.length; ++i) {
      all = all.concat(zsToday[i] ?? []);
    }

    const selected = [zsToday[zIndex]] ?? [];

    return [selected, all];
  }, [zIndex, zsToday]);

  const data = userZStats(selectedZ);
  const allData = userZStats(allZ);
  const userData = useUserStats(userStat);

  const zTotal = useMemo(() => totalZ(zsToday), [zsToday]);
  const zN1Total = useMemo(() => totalZ(zN1), [zN1]);
  const zPreviousTotal = useMemo(() => {
    return zsPrevious ? zsPrevious.map(zs => totalZ(zs)).reverse() : Array(8).fill(0);
  }, [zsPrevious]);

  if (loading)
    return <Loader active size='big' />;

  const zSelectMenu = [];
  if (data && zsToday) {
    for (let i = 0; i < zsToday.length; ++i)
      zSelectMenu.push(
        <Menu.Item
          key={i}
          as={Button}
          active={i === zIndex}
          onClick={() => setZIndex(i)}
        >
          <span>{`Z \nn°${zsToday[i].zId ?? '-'}`}</span>
        </Menu.Item>
      )
  }


  return (
    <Container>
      <Breakpoint small down style={{ position: 'absolute', top: '22px', left: '70px' }}>
        <h3>Rapport Z</h3>
      </Breakpoint>
      <Grid padded stackable>
        <Grid.Column width={16}>
          <Menu style={{ overflow: 'auto' }}>
            <MenuCalendar
              date={date}
              setDate={dateChangeCallback}
            />
            {zSelectMenu}
            <Breakpoint small up>
              {data &&
                <ExportExcelZ data={allData} resto={props.resto} date={date} />
              }
            </Breakpoint>
          </Menu>
        </Grid.Column>
        {!data ? (
          <h2 className='center-absolute'>Pas de cloture à cette date.</h2>
        ) : (
          <>

            <Grid.Column width={5}>
              <StatCard
                title='Clients'
                value={data.statistic.totalPerson}
                color='#ED5394'
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <StatCard
                title='Moyenne des tickets (TTC)'
                value={data.statistic.averageCart ? data.statistic.averageCart.toFixed(2) : '0.00'}
                color={'#20CD7A'}
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <StatCard
                title='Ticket moyen (par personne en TTC)'
                value={data.statistic.averagePerson ? data.statistic.averagePerson.toFixed(2) : '0.00'}
                color={'#faa157'}
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <StatCard
                title='CA TTC réalisé'
                value={data.turnover.totalTtc.toFixed(2)}
                color={'#466181'}
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <StatCard
                title=''
                value={(data.statistic.totalCode30 + data.turnover.totalTtc).toFixed(2)}
                color={'#466181'}
              />
            </Grid.Column>

            <Grid.Column width={8}>
              <Grid stackable>
                <Grid.Column width={16}>
                  <div className='column-container'>
                    <h3 style={{ textAlign: 'center' }}>Comparaison année précédente</h3>
                    <ComparaisonN1Chart data={{
                      labels: [sub(date, { years: 1 }).toLocaleDateString('fr-FR'), date.toLocaleDateString('fr-FR')],
                      datasets: [
                        {
                          label: 'Chiffre d\'affaire TTC',
                          backgroundColor: 'rgba(255,99,132,0.2)',
                          borderColor: 'rgba(255,99,132,1)',
                          borderWidth: 1,
                          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                          hoverBorderColor: 'rgba(255,99,132,1)',
                          data: [zN1Total, zTotal]
                        }
                      ]
                    }} />
                  </div>
                </Grid.Column>

                <Grid.Column width={16}>
                  <div className='column-container'>
                    <h3 style={{ textAlign: 'center' }}>Comparaison 8 derniers jours</h3>
                    <ComparaisonPeriodeChart data={{
                      labels: ['J-8', 'J-7', 'J-6', 'J-5', 'J-4', 'J-3', 'J-2', 'J-1', date.toLocaleDateString()],
                      datasets: [
                        {
                          label: 'Chiffre d\'affaire TTC',
                          fill: false,
                          lineTension: 0.1,
                          backgroundColor: 'rgba(75,192,192,0.4)',
                          borderColor: 'rgba(75,192,192,1)',
                          borderCapStyle: 'butt',
                          borderDash: [],
                          borderDashOffset: 0.0,
                          borderJoinStyle: 'miter',
                          pointBorderColor: 'rgba(75,192,192,1)',
                          pointBackgroundColor: '#fff',
                          pointBorderWidth: 1,
                          pointHoverRadius: 5,
                          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                          pointHoverBorderColor: 'rgba(220,220,220,1)',
                          pointHoverBorderWidth: 2,
                          pointRadius: 1,
                          pointHitRadius: 10,
                          data: [...zPreviousTotal, zTotal]
                        }
                      ]
                    }} />
                  </div>
                </Grid.Column>


              </Grid>
            </Grid.Column>

            <Grid.Column width={8}>
              <Grid stackable>


                <Grid.Column width={8}>
                  <div className='column-container'>
                    <h3 style={{ textAlign: 'center' }}>Modes de paiement</h3>
                    <PaiementsChart data={data.payment} />
                  </div>
                </Grid.Column>

                <Grid.Column width={8}>
                  <div className='column-container'>
                    <h3 style={{ textAlign: 'center' }}>Chiffre d'affaire</h3>
                    <CATable data={data.turnover} />
                  </div>
                </Grid.Column>

                <Grid.Column width={8}>
                  <div className='column-container'>
                    <h3 style={{ textAlign: 'center' }}>Statistique</h3>
                    <StatsTable data={data.statistic} />
                  </div>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </>
        )}

      </Grid>
    </Container>
  );
}

export default connect(
  (state) => ({
    resto: state.resto.currentResto,
  }),
)(RapportZ);