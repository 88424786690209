import React from 'react';
import { Table } from 'semantic-ui-react';

const PaiementsChart = (props) => {
  return (
    <Table unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Paiement</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Espèce</Table.Cell>
          <Table.Cell textAlign='right'>{props.data?.totalPayment1 ? props.data.totalPayment1.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Carte bleu</Table.Cell>
          <Table.Cell textAlign='right'>{props.data?.totalPayment2 ? props.data.totalPayment2.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Ticket Restaurant</Table.Cell>
          <Table.Cell textAlign='right'>{props.data?.totalPayment3 ? props.data.totalPayment3.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Chèque</Table.Cell>
          <Table.Cell textAlign='right'>{props.data?.totalPayment4 ? props.data.totalPayment4.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Chèque vacance</Table.Cell>
          <Table.Cell textAlign='right'>{props.data?.totalPayment5 ? props.data.totalPayment5.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Avoir</Table.Cell>
          <Table.Cell textAlign='right'>{props.data?.totalPayment7 ? props.data.totalPayment7.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Autre</Table.Cell>
          <Table.Cell textAlign='right'>{props.data?.totalPayment6 ? props.data.totalPayment6.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Autre 2</Table.Cell>
          <Table.Cell textAlign='right'>{props.data?.totalPayment8 ? props.data.totalPayment8.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Autre 3</Table.Cell>
          <Table.Cell textAlign='right'>{props.data?.totalPayment9 ? props.data.totalPayment9.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

export default PaiementsChart;