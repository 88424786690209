import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

// https://reacttraining.com/react-router/web/example/auth-workflow
class PrivateRoute extends Component {
  render() {
    const { component: Component, ...rest} = this.props;
    let isLogged = this.props.user !== null;
    if (this.props.admin && !this.props.user.isAdmin) {
      isLogged = false;
    }
    return (
      <Route
        {...rest}
        render = { props =>
          isLogged ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
)(PrivateRoute);