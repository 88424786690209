import React from 'react';
import { Redirect } from 'react-router-dom';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'

const LogoutPage = () => {
  firebase.auth().signOut();
  return (
    <Redirect to='/' />
  );
}

export default LogoutPage;