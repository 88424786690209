import React from 'react';
import { Table } from 'semantic-ui-react';

const StatsTable = (props) => {
  return (
    <Table unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Nombre</Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>Total CA</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Sur place</Table.Cell>
          <Table.Cell textAlign='center'>{props.data.totalTable || '0'}</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalTableTtc ? props.data.totalTableTtc.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Bar</Table.Cell>
          <Table.Cell textAlign='center'>{props.data.totalBar || '0'}</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalBarTtc ? props.data.totalBarTtc.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Emporté</Table.Cell>
          <Table.Cell textAlign='center'>{props.data.totalTakeAway || '0'}</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalTakeAwayTtc ? props.data.totalTakeAwayTtc.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Livraison</Table.Cell>
          <Table.Cell textAlign='center'>{props.data.totalDelivery || '0'}</Table.Cell>
          <Table.Cell textAlign='right'>{props.data.totalDeliveryTtc ? props.data.totalDeliveryTtc.toFixed(2) : '0.00'}</Table.Cell>
        </Table.Row>
        {/* <Table.Row>
          <Table.Cell>Reste à encaisser</Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell>{props.data.resteEncaisser || '0.00'}€</Table.Cell>
        </Table.Row> */}
      </Table.Body>
    </Table>
  );
}

export default StatsTable;