import React from 'react';
import { Line } from 'react-chartjs-2';
import { Segment } from 'semantic-ui-react';

const options = {
  scales: {
    yAxes: [{
      ticks: { beginAtZero: true }
    }]
  },
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';

        if (label) label += ': ';
        label += tooltipItem.yLabel.toFixed(2);
        return label;
      }
    }
  }
};

const ComparaisonPeriodeChart = (props) => {

  return (
    <Segment style={{ boxShadow: 'none' }}>
      <Line data={props.data} options={options} />
    </Segment>
  );
}

export default ComparaisonPeriodeChart;