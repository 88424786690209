import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { removeResto } from '../redux/actions';

import { NavLink } from 'react-router-dom';
import { Grid, Icon, Menu, Sidebar, Button } from 'semantic-ui-react';
import WaveSVG from './WaveSVG';
import { Breakpoint } from 'react-socks';

const menuItems = [
  {
    text: 'Temps réel',
    name: 'temps-reel',
    link: '/temps-reel',
    icon: 'server',
    exact: true,
    color: 'red'
  },
  {
    text: 'Rapport Z',
    name: 'rapportZ',
    link: '/rapport-z',
    icon: 'file alternate outline',
    exact: true,
    color: 'teal',
  },
  {
    text: 'Reporting mensuel',
    name: 'reporting',
    link: '/reporting',
    icon: 'chart bar',
    exact: true,
    color: 'blue',
  },
  {
    text: 'Réservations',
    name: 'reservations',
    link: '/reservations',
    icon: 'table',
    exact: true,
    color: 'brown'
  },
  {
    text: 'Carte',
    name: 'carte',
    link: '/carte',
    icon: 'food',
    color: 'orange',
    admin: true,
  },
  {
    text: 'Comptes',
    name: 'compte',
    link: '/comptes',
    icon: 'user',
    color: 'green',
    admin: true,
  }
];

/**
 * Composant sidebar. Les liens de la sidebar sont configurables avec l'array menuItems
 * @props onHide: (string?) => void -- Fonction appelee quand le bouton pour cacher la sidebar est clické (seulement taille mobile)
 * @props restoName: string? -- Affichage du nom dans la sidebar
 * @props email: string? -- Affichage de l'email dans la sidebar
 * @props allResto: [Resto] -- Permet l'affichage du bouton pour changer de resto s'il y a plus d'un resto
 * @props user: User -- L'utilisateur connecté
 */
const SideBar = (props) => {
  const onHide = props.onHide;
  const hide = useCallback(() => onHide ? onHide('mounted') : null, [onHide]);

  return (
    <>
    <Breakpoint small down>
      {props.visible && (
        <div
          className='sidebar-dimmer'
          onClick={hide}
        />
      )}
    </Breakpoint>
    <Sidebar as={Menu} vertical visible={props.visible} borderless animation='overlay' style={{ display: 'flex', width: 250, border: 'none' }}>
      <Menu.Item style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', background: 'linear-gradient(153deg, #0f2854 0%, #1d456a 100%)', padding: '10px 0 0'}}>
        <Grid columns='3' textAlign='center' style={{ marginLeft: 0, marginRight: 0 }}>
          <Grid.Column />
          <Grid.Column>
            <img src='/logo-easoft-no-bg.png' alt='Logo EASoft' />
          </Grid.Column>
          <Grid.Column textAlign='right' style={{ paddingRight: 0 }}>
            <Breakpoint small down>
              <Button size='big' onClick={hide} icon='angle double left' id='change-restaurant-button' />
            </Breakpoint>
          </Grid.Column>
        </Grid>
        <div style={{ margin: '50px 0' }}>
          <h2 style={{ color: 'white', textAlign: 'center', marginBottom: 5 }}>{props.restoName || '/'}</h2>
          <div style={{ color: '#D9D9D9', textAlign: 'center' }}>{props.email || '/'}</div>
        </div>
        {(props.allRestos.length > 1 || props.user.isAdmin) && (
          <div style={{ textAlign: 'right'}}>
            <Button
              id='change-restaurant-button'
              basic
              active
              onClick={props.changeResto}
            >Changer de restaurant ></Button>
          </div>
        )}
        <WaveSVG />
      </Menu.Item>
      {menuItems.map(item => {
        if (item.admin && !props.user.isAdmin)
          return null;

        return (
          <Menu.Item key={item.name}
            exact={item.exact}
            as={NavLink}
            to={item.link}
            name={item.name}
            activeClassName='active'
            style={{ paddingLeft: 40 }}
            onClick={hide}
          >
            <div>
              <Icon name={item.icon} color={item.color} />
              {item.text}
            </div>
          </Menu.Item>
        );
      })}
      <Menu.Item
        exact
        as={NavLink}
        to={'/deconnexion'}
        style={{ marginTop: 'auto'}}
        active
      >
        <h4 style={{ textAlign: 'center' }}>
          Déconnexion
        </h4>
      </Menu.Item>
    </Sidebar>
    </>
  );
}

export default connect(
  (state) => ({
    allRestos: state.auth.allRestos,
    user: state.auth.user,
  }),
  (dispatch) => ({
    changeResto: () => dispatch(removeResto()),
  }),
)(SideBar);