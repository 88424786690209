import React from 'react';

const StatCard = (props) => {
  return (
    <div className='stat-card' style={{ backgroundColor: props.color || 'inherit'}}>
      <h4>
        {props.title}
      </h4>
      <h1>
        {props.value}
      </h1>
    </div>
  );
}

export default StatCard;