import { useEffect, useMemo, useState } from 'react';

function getStats(Orders) {
  let length = 0
  let totalPerson = 0
  let totalTtc = 0
  for (let i = 0; i < Orders.length; i++) {
    const order = Orders[i];
    if (order.canceled) continue;
    length++;
    if (Number.isInteger(order.nbCustomer)) totalPerson += order.nbCustomer
    totalTtc += order.totalTtc
  }
  return { length, totalPerson, totalTtc }
}

const useOrderStats2 = (tickets, serveur = 0) => {
  const stats = useMemo(() => {
    if (!tickets || tickets.length === 0) {
      return { ticket: [], totalPerson: 0, totalTtc: 0, };
    }
    else if (tickets.length > 0) {
      let filteredTickets = tickets;
      if (serveur !== 0)
        filteredTickets = filteredTickets.filter(t => t.userId === serveur);

      const data = getStats(filteredTickets);
      data.ticket = filteredTickets
      return data;
    }
  }, [tickets, serveur]);

  return stats;
};

export default useOrderStats2;