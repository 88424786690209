export const vatValues = [{
  vatId: 1,
  vatRate: 5.5,
}, {
  vatId: 2,
  vatRate: 10,
}, {
  vatId: 3,
  vatRate: 20,
}];


export const createDefaultProduct = () => ({
  code: '',
  name: '',
  appName: '',
  kitchenName: '',
  priceTtc: 0,
  priceHt: 0,
  categoryId: 0,
  subCategoryId: 0,
  vatId: vatValues[1].vatId,
  vatRate: vatValues[1].vatRate,
  gram: false,
  color: '',
  type: 'product',
  canDiscount: true,

  stockActivated: false,
  stockQuantity: 0,
  manualPrice: false,
  hasImage: false,
});

export const createDefaultCategories = () => ([{
  id: 1,
  name: '',
  color: '#1976D2',
  logo: '1',
}, {
  id: 2,
  name: '',
  color: '#1976D2',
  logo: '1',
}, {
  id: 3,
  name: '',
  color: '#1976D2',
  logo: '1',
}, {
  id: 4,
  name: '',
  color: '#1976D2',
  logo: '1',
}, {
  id: 5,
  name: '',
  color: '#1976D2',
  logo: '1',
}, {
  id: 6,
  name: '',
  color: '#1976D2',
  logo: '1',
}, {
  id: 7,
  name: '',
  color: '#1976D2',
  logo: '1',
}, {
  id: 8,
  name: '',
  color: '#1976D2',
  logo: '1',
}]);