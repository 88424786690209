import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';

const materialColors = [
  ['#E57373', '#EF5350', '#F44336', '#E53935', '#D32F2F', '#C62828', '#B71C1C'], // red
  ['#F06292', '#EC407A', '#E91E63', '#D81B60', '#C2185B', '#AD1457', '#880E4F'], // pink
  ['#BA68C8', '#AB47BC', '#9C27B0', '#8E24AA', '#7B1FA2', '#6A1B9A', '#4A148C'], // purple
  ['#7986CB', '#5C6BC0', '#3F51B5', '#3949AB', '#303F9F', '#283593', '#1A237E'], // indigo
  ['#64B5F6', '#42A5F5', '#2196F3', '#1E88E5', '#1976D2', '#1565C0', '#0D47A1'], // blue
  ['#4DD0E1', '#26C6DA', '#00BCD4', '#00ACC1', '#0097A7', '#00838F', '#006064'], // cyan
  ['#4DB6AC', '#26A69A', '#009688', '#00897B', '#00796B', '#00695C', '#004D40'], // teal
  ['#81C784', '#66BB6A', '#4CAF50', '#43A047', '#388E3C', '#2E7D32', '#1B5E20'], // green
  ['#FFD54F', '#FFCA28', '#FFC107', '#FFB300', '#FFA000', '#FF8F00', '#FF6F00'], // amber
  ['#FF8A65', '#FF7043', '#FF5722', '#F4511E', '#E64A19', '#D84315', '#BF360C'], // orange
  ['#A1887F', '#8D6E63', '#795548', '#6D4C41', '#5D4037', '#4E342E', '#3E2723'], // brownn
  ['#90A4AE', '#78909C', '#607D8B', '#546E7A', '#455A64', '#37474F', '#263238'], // grey
];

const ColorPickerPalette = (props) => {
  return (
    <div style={{ display: 'flex'}}>
      {materialColors.map((colorCategory, i) => (
        <div key={i}>
          {colorCategory.map(color => (
            <div
              key={color}
              className='color-picker-square'
              style={{ backgroundColor: color}}
              onClick={() => props.onChange(color)}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

/**
 *
 * @param value string
 * @param onChange (color: string) => void
 */
const ColorPicker = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popup
      open={isOpen}
      trigger={<div
        style={{ backgroundColor: props.value || materialColors[0][0] }}
        className='color-picker-square'
      />}
      content={<ColorPickerPalette onChange={(value) => {
        setIsOpen(false);
        props.onChange(value);
      }} />}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      on='click'
    />


  );
}

export default ColorPicker;