import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import firebaseConfig from './firebase.config';
import { setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr'
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { BreakpointProvider, Breakpoint } from 'react-socks';
import { login, logout, setResto } from './redux/actions';
import Parse from 'parse/dist/parse.min.js';
import RestaurantPick from './pages/RestaurantPick';
import Router from './components/AppRouter';
import SideBar from './components/SideBar';
import SideBarToggleButton from './components/SideBarToggleButton';

import { userIsAdmin } from './config/auth';

firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig, 'admin');
setDefaultLocale(fr);

function deriveBoolFromState(sidebarState) {
  if (sidebarState === null)
    return false;
  if (sidebarState === 'mounted')
    return false;
  if (sidebarState === 'clicked')
    return true;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initializing: true,
      user: {
        restoName: '',
        email: '',
      },
      sidebarState: null,
    }
  }

  initParse =  restoId => new Promise(async (resolve, reject) => {
    const userDoc = await firebase.firestore().collection('Restaurant').doc(restoId).get({ source: 'server' })
      if (!userDoc.exists) return
      const backofficeUrl = userDoc.data().backoffice?.url
      const backofficeAppId = await userDoc.data().backoffice?.appId
      const backofficeKey = await userDoc.data().backoffice?.key
      if (backofficeUrl && backofficeAppId && backofficeKey) {
        Parse.initialize(
          backofficeAppId, // This is your Application ID
          backofficeKey // This is your Javascript key
        )
        Parse.serverURL = backofficeUrl
        resolve("I am done");
      } else {
        resolve("I am done 2");
      }
  });

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async user => {
      if (user !== null && user.emailVerified) {
        const userDoc = await firebase.firestore().collection('user').doc(user.uid).get();
        if (userDoc.exists) {
          const restos = userDoc.data().resto;
          if (restos.length === 1) {

            await this.initParse(restos[0].id)

            this.props.setResto({
              name: restos[0].name,
              id: restos[0].id,
            });
          }

          this.props.login({
            user: {
              id: user.uid,
              email: user.email,
              isAdmin: userIsAdmin({ id: user.uid }),
            },
            allRestos: restos
          });
        }
        else {
          // todo: show error message
          firebase.auth().logout();
        }
      }
      else {
        this.props.logout();
        this.props.setResto(null);
      }

      if (this.state.initializing === true)
        this.setState({ initializing: false });
    });
  }

  render() {
    if (this.state.initializing)
      return <Loader active size='massive'>Chargement</Loader>;
    return (
      <BreakpointProvider>
        <BrowserRouter>
          {/* Mobile app */}
          <Breakpoint small down>
            {this.props.user !== null && this.props.resto !== null && (
              <>
                <SideBarToggleButton onVisibilityChange={(visibility) => { this.setState({ sidebarState: visibility }) }} />
                <SideBar onHide={(visiblity) => this.setState({ sidebarState: 'mounted' })} visible={deriveBoolFromState(this.state.sidebarState)} email={this.props.user.email} restoName={this.props.resto.name} />
              </>
            )}
            {this.props.user !== null && this.props.resto === null ? (
              <RestaurantPick />
            ) : (
              <Router />
            )}
          </Breakpoint>

          {/* Desktop app */}
          <Breakpoint medium up>
            {this.props.user !== null && this.props.resto !== null && (
              <SideBar visible={true} email={this.props.user.email} restoName={this.props.resto.name} />
            )}
            {this.props.user !== null && this.props.resto === null ? (
              <RestaurantPick />
            ) : (
              <div className='desktop-container'>
                <Router />
              </div>
            )}
          </Breakpoint>
        </BrowserRouter>
      </BreakpointProvider>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
    resto: state.resto.currentResto,
  }),
  (dispatch) => ({
    login: (user) => dispatch(login(user)),
    logout: () => dispatch(logout()),
    setResto: (resto) => dispatch(setResto(resto)),
  })
)(App);
