import React, { useEffect, useState } from 'react';
import { Grid, Menu, Container, Button, Loader } from 'semantic-ui-react';
import { Breakpoint } from 'react-socks';

import MenuCalendar from '../components/MenuCalendar';
import { CATable, ComparaisonPeriodeChart, MeilleursVentesChart, PaiementsChart, StatsTable, StatCard } from '../components/charts';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import ExportExcelMonth from '../components/ExportExcelMonth';
import useUserStats from '../hooks/useUserStats';
import Parse from 'parse/dist/parse.min.js';

const data2 = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
  datasets: [
    {
      label: 'My First dataset',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40]
    }
  ]
};

const getMonthDates = (date) => {
  const startDate = new Date(date);
  startDate.setDate(1);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59);
  return [startDate, endDate];
}

const getYearDates = (date) => {
  const startDate = new Date(date);
  startDate.setDate(1);
  startDate.setMonth(0);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(startDate.getFullYear(), 12, 0, 23, 59, 59);
  return [startDate, endDate];
}

const getReportDateFromZArray = (zArray) => {
  let totalTableTtc = 0.00
  let totalDeliveryTtc = 0.00
  let totalTakeAwayTtc = 0.00
  let totalBarTtc = 0.00
  let totalTable = 0
  let totalTable2 = 0
  let totalTable3 = 0
  let totalDelivery = 0
  let totalBar = 0
  let totalPerson = 0
  let totalTakeAway = 0
  let totalTtc = 0
  let totalHt = 0
  let totalHt1 = 0
  let totalHt2 = 0
  let totalHt3 = 0
  let totalVat1 = 0
  let totalVat2 = 0
  let totalVat3 = 0
  let totalVat = 0
  let totalTtc1 = 0
  let totalTtc2 = 0
  let totalTtc3 = 0
  let vatRate1 = 0
  let vatRate2 = 0
  let vatRate3 = 0
  let ESP = 0
  let CB = 0
  let TR = 0
  let CQ = 0
  let CV = 0
  let Autre = 0
  let Credit = 0
  let Autre2 = 0
  let Autre3 = 0
  let totalCredit = 0
  let totalCode30 = 0
  let zArr = []

  for (let i = 0; i < zArray.length; i++) {
    totalTableTtc += zArray[i].attributes.totalTableTtc;
    totalDeliveryTtc += zArray[i].attributes.totalDeliveryTtc;
    totalTakeAwayTtc += zArray[i].attributes.totalTakeAwayTtc;
    totalBarTtc += zArray[i].attributes.totalBarTtc;
    totalTable += zArray[i].attributes.totalTable;
    totalTable2 += zArray[i].attributes.totalTable2 ? zArray[i].attributes.totalTable2 : 0;
    totalTable3 += zArray[i].attributes.totalTable3 ? zArray[i].attributes.totalTable3 : 0;
    totalDelivery += zArray[i].attributes.totalDelivery;
    totalBar += zArray[i].attributes.totalBar;
    totalPerson += zArray[i].attributes.totalPerson;
    totalTakeAway += zArray[i].attributes.totalTakeAway;
    totalTtc += zArray[i].attributes.totalTtc;
    totalHt += zArray[i].attributes.totalHt;
    totalHt1 += zArray[i].attributes.totalHt1;
    totalHt2 += zArray[i].attributes.totalHt2;
    totalHt3 += zArray[i].attributes.totalHt3;
    totalVat1 += zArray[i].attributes.totalVat1;
    totalVat2 += zArray[i].attributes.totalVat2;
    totalVat3 += zArray[i].attributes.totalVat3;
    totalVat += zArray[i].attributes.totalVat;
    totalTtc1 += zArray[i].attributes.totalTtc1;
    totalTtc2 += zArray[i].attributes.totalTtc2;
    totalTtc3 += zArray[i].attributes.totalTtc3;
    vatRate1 = zArray[i].attributes.vatRate1;
    vatRate2 = zArray[i].attributes.vatRate2;
    vatRate3 = zArray[i].attributes.vatRate3;
    ESP += zArray[i].attributes.totalPayment1;
    CB += zArray[i].attributes.totalPayment2;
    TR += zArray[i].attributes.totalPayment3;
    CQ += zArray[i].attributes.totalPayment4;
    CV += zArray[i].attributes.totalPayment5;
    Autre += zArray[i].attributes.totalPayment6;
    Credit += zArray[i].attributes.totalPayment7;
    Autre2 += zArray[i].attributes.totalPayment8;
    Autre3 += zArray[i].attributes.totalPayment9;
    if (zArray[i].attributes.paybackCash !== null && zArray[i].attributes.paybackCash > 0)
      ESP -= zArray[i].attributes.paybackCash;
    totalCredit += zArray[i].attributes.totalCredit;

    const details = zArray[i].attributes.userStat ?? {}
    totalCode30 += details?.code30?.amount ?? 0

    zArr = zArr.concat({
      ...zArray[i].attributes,
      totalPayment: zArray[i].attributes.totalPayment1 + zArray[i].attributes.totalPayment2 + zArray[i].attributes.totalPayment3 + zArray[i].attributes.totalPayment4 + zArray[i].attributes.totalPayment5 + zArray[i].attributes.totalPayment6 + zArray[i].attributes.totalPayment7 + zArray[i].attributes.totalPayment8 + zArray[i].attributes.totalPayment9 - zArray[i].attributes.totalCredit,
      finishedOrder: [],
    })
  }

  return {
    totalTtc,
    totalHt,
    totalHt1,
    totalHt2,
    totalHt3,
    totalVat1,
    totalVat2,
    totalVat3,
    totalVat,
    totalTtc1,
    totalTtc2,
    totalTtc3,
    vatRate1,
    vatRate2,
    vatRate3,
    totalTable,
    totalTable2,
    totalTable3,
    totalTakeAway,
    totalDelivery,
    totalBar,
    totalPerson,
    totalTableTtc,
    totalTakeAwayTtc,
    totalDeliveryTtc,
    totalBarTtc,
    totalPayment1: ESP,
    totalPayment2: CB,
    totalPayment3: TR,
    totalPayment4: CQ,
    totalPayment5: CV,
    totalPayment6: Autre,
    totalPayment7: Credit,
    totalPayment8: Autre2,
    totalPayment9: Autre3,
    totalPayment: ESP + CB + TR + CQ + CV + Autre + Credit + Autre2 + Autre3 - totalCredit,
    totalCredit,
    totalCode30,
    zArray: zArr,
    averageCart: totalTtc / (totalDelivery + totalBar + totalTakeAway + totalTable),
    averagePerson: totalTableTtc / totalPerson
  }
}

const useReport = (restoId, date, reportType) => {
  const [report, setReport] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);

    const request2 = async () => {
      const [startDate, endDate] = reportType === 'month' ? getMonthDates(date) : getYearDates(date)
      const GameScore = Parse.Object.extend(`Z_${restoId}`)
      const query = new Parse.Query(GameScore)
      query.greaterThan('startAt', startDate)
      query.lessThan('startAt', endDate)
      query.limit(5000)
      const nbResult = await query.count()
      if (nbResult > 0) {
        const result = await query.find();
        const data = getReportDateFromZArray(result)
        console.log(data)
        setReport(data);
      }
      else
        setReport(null);

      setLoad(false);
    }
    request2();
  }, [restoId, date, reportType]);

  return [report, load];
}

const useUserStat = (restoId, date, reportType) => {
  const [z, setZ] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    const request = async () => {
      const GameScore = Parse.Object.extend(`UserStat_${restoId}`)
      const query = new Parse.Query(GameScore);
      const [startDate, endDate] = reportType === 'month' ? getMonthDates(date) : getYearDates(date);
      console.log(startDate, endDate)
      query.greaterThan('date', startDate)
      query.lessThan('date', endDate)
      const nbResult = await query.count()
      const todayZ = []
      if (nbResult > 0) {
        const result = await query.find()
        for (let i = 0; i < result.length; i++) {
          todayZ.push(result[i].attributes)
        }
        setZ(todayZ)
      } else {
        setZ(null);
      }
      setLoad(false);
    }
    request();
  }, [restoId, date]);

  return [z, load];
}


const Reporting = (props) => {
  const [date, setDate] = useState(new Date());
  const [reportType, setReportType] = useState('month');
  const [report, loading] = useReport(props.resto.id, date, reportType);
  const [userStat, load] = useUserStat(props.resto.id, date, reportType)
  const userData = useUserStats(userStat);


  if (loading)
    return <Loader active size='big' />;

  return (
    <Container>
      <Breakpoint small down style={{ position: 'absolute', top: '22px', left: '70px' }}>
        <h3>Reporting mensuel</h3>
      </Breakpoint>
      <Grid padded stackable>
        <Grid.Column width={16}>
          <Menu>
            {/* <Menu.Item
              as={Button}
              onClick={() => setReportType('month')}
              active={reportType === 'month'}
            >Mensuel</Menu.Item>
            <Menu.Item
              as={Button}
              onClick={() => setReportType('year')}
              active={reportType === 'year'}
            >Annuel</Menu.Item> */}
            <MenuCalendar
              date={date}
              setDate={setDate}
              pickerType={reportType}
            />
            <Breakpoint small up>
              {reportType === 'month' &&
                <ExportExcelMonth date={date} />
              }
            </Breakpoint>
          </Menu>
        </Grid.Column>
        {report === null ? (
          <h2 className='center-absolute'>Pas de rapport pour {reportType === 'month' ? 'ce mois' : 'cette année'}.</h2>
        ) : (
          <>
            <Grid.Column width={5}>
              <StatCard
                title='Clients'
                value={report.totalPerson}
                color='#ED5394'
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <StatCard
                title='Moyenne des tickets (TTC)'
                value={report.averageCart.toFixed(2)}
                color={'#20CD7A'}
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <StatCard
                title='Ticket moyen (par personne en TTC)'
                value={report.averagePerson ? report.averagePerson.toFixed(2) : '0.00'}
                color={'#faa157'}
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <StatCard
                title='CA TTC réalisé'
                value={report.totalTtc.toFixed(2)}
                color={'#466181'}
              />
            </Grid.Column>


            <Grid.Column width={5}>
              <StatCard
                title=''
                value={(report.totalCode30 + report.totalTtc).toFixed(2)}
                color={'#466181'}
              />
            </Grid.Column>

            <Grid.Column width={16}>
              <Grid stackable>
                <Grid.Column width={4}>
                  <h3 style={{ textAlign: 'center' }}>Chiffre d'affaire</h3>
                  <CATable data={report} />
                </Grid.Column>


                <Grid.Column width={4}>
                  <h3 style={{ textAlign: 'center' }}>Modes de paiement</h3>
                  <PaiementsChart data={report} />
                </Grid.Column>

                <Grid.Column width={4}>
                  <h3 style={{ textAlign: 'center' }}>Statistique</h3>
                  <StatsTable data={report} />
                </Grid.Column>


                {/* <Grid.Column width={8}>
              <h3 style={{ textAlign: 'center' }}>Meilleures ventes</h3>
              <MeilleursVentesChart height={350} data={report.productStat} />
            </Grid.Column> */}
              </Grid>
            </Grid.Column>
          </>
        )}
      </Grid>
    </Container>
  );
}

export default connect(
  (state) => ({
    resto: state.resto.currentResto,
  }),
)(Reporting);