import React, { useState, useCallback } from 'react';
import { Grid, Form, Modal, Table } from 'semantic-ui-react';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import isSameDay from 'date-fns/isSameDay';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { fr } from 'date-fns/locale';
registerLocale('fr', fr);
setDefaultLocale('fr');

const updateReservation = (restoId, reservation) => {
  const { id, ...data } = reservation
  firebase.firestore()
    .collection('Restaurant')
    .doc(restoId)
    .collection('Reservation')
    .doc(id)
    .update(data);
}

const ReservationList = (props) => {
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const onReservationUpdate = useCallback(() => {
    updateReservation(props.resto.id, selectedReservation);
    setSelectedReservation(null);
  }, [props.resto.id, selectedReservation]);

  return (
    <Grid padded stackable>
      <Grid.Row>
        <Grid.Column width='6' textAlign='center'>
          <ReactDatePicker
            inline
            disabledKeyboardNavigation
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className="react-datepicker__header-container">
                <button
                  className="react-datepicker__navigation react-datepicker__navigation--previous"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                />
                <div className="react-datepicker__current-month">
                  {format(date, 'MMMM yyyy', { locale: fr })}
                </div>
                <button
                  className="react-datepicker__navigation react-datepicker__navigation--next"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                />
              </div>
            )}
            dayClassName={date => props.reservations.some(r => isSameDay(r.reservationDate, date)) && 'has-reservations'}
          />
        </Grid.Column>

        <Grid.Column width='10'>
          <Table selectable unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Heure</Table.HeaderCell>
                <Table.HeaderCell>A / E</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {props.reservations.length > 0 && props.reservations.map(r => isSameDay(r.reservationDate, selectedDate) && (
                <Table.Row onClick={() => setSelectedReservation(r)}>
                  <Table.Cell>{r.lastName} {r.firstName}</Table.Cell>
                  <Table.Cell>{format(r.reservationDate, 'HH:mm')}</Table.Cell>
                  <Table.Cell>{r.nbAdult} / {r.nbChild}</Table.Cell>
                  <Table.Cell>{r.status || 'En attente'}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>

        {selectedReservation !== null && (
          <Modal
            open={selectedReservation !== null}
            header={'Modifier une réservation'}
            content={() => (
              <Modal.Content>
                <Form>
                  <Form.Select
                    label='Status'
                    placeholder='Status'
                    options={[
                      { key: 'En attente', text: 'En attente', value: 'En attente' },
                      { key: 'Confirmée', text: 'Confirmée', value: 'Confirmée' },
                    ]}
                    value={selectedReservation.status}
                    onChange={(e, { value }) => setSelectedReservation({ ...selectedReservation, status: value })}
                  />
                  <Form.Group widths='equal'>
                    <Form.Input
                      fluid
                      label='Nom'
                      placeholder='Nom'
                      value={selectedReservation.lastName}
                      onChange={(e, { value }) => setSelectedReservation({ ...selectedReservation, lastName: value })}
                    />

                    <Form.Input
                      fluid
                      label='Prénom'
                      placeholder='Prénom'
                      value={selectedReservation.firstName}
                      onChange={(e, { value }) => setSelectedReservation({ ...selectedReservation, firstName: value })}
                    />
                  </Form.Group>

                  <Form.Group widths='equal'>
                    <Form.Input
                      fluid
                      label='Téléphone'
                      placeholder='Téléphone'
                      type='tel'
                      value={selectedReservation.phone}
                      onChange={(e, { value }) => setSelectedReservation({ ...selectedReservation, phone: value })}
                    />

                    <Form.Input
                      fluid
                      label='Email'
                      placeholder='Email'
                      value={selectedReservation.email}
                      onChange={(e, { value }) => setSelectedReservation({ ...selectedReservation, email: value })}
                    />
                  </Form.Group>

                  <Form.Group widths='4'>
                    <div className='field'>
                      <label>Date</label>
                      <Form.Input
                        fluid
                        label='Date'
                        placeholder='Date'
                        as={ReactDatePicker}
                        dateFormat="dd/MM/yyyy"
                        selected={selectedReservation.reservationDate}
                        onChange={(date) => setSelectedReservation({ ...selectedReservation, reservationDate: date })}
                      />
                    </div>

                    <Form.Input
                      fluid
                      label='Heure'
                      placeholder='Heure'
                      type='time'
                      value={format(selectedReservation.reservationDate, 'HH:mm')}
                      onChange={(e, { value }) => setSelectedReservation({ ...selectedReservation, reservationDate: parse(value, 'HH:mm', selectedReservation.reservationDate) })}
                    />

                    <Form.Input
                      fluid
                      label='Adultes'
                      placeholder='Adultes'
                      type='number'
                      value={selectedReservation.nbAdult}
                      onChange={(e, { value }) => setSelectedReservation({ ...selectedReservation, nbAdult: value })}
                    />

                    <Form.Input
                      fluid
                      label='Enfants'
                      placeholder='Enfants'
                      type='number'
                      value={selectedReservation.nbChild}
                      onChange={(e, { value }) => setSelectedReservation({ ...selectedReservation, nbChild: value })}
                    />
                  </Form.Group>

                  <Form.TextArea
                    fluid
                    label='Commentaire'
                    placeholder='Commentaire'
                    value={selectedReservation.comment}
                    onChange={(e, { value }) => setSelectedReservation({ ...selectedReservation, comment: value })}
                  />
                </Form>
              </Modal.Content>
            )}
            actions={['Annuler', { content: 'Modifier', primary: true, onClick: onReservationUpdate }]}
            onClose={() => setSelectedReservation(null)}
          />
        )}
      </Grid.Row>
    </Grid>
  )
}

export default ReservationList;
