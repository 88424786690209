import React, { useState, useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import { connect } from 'react-redux';
import { setResto } from '../redux/actions';
import { Header, Grid, Form, Button, Segment, Message } from 'semantic-ui-react';
import WaveSVG from '../components/WaveSVG';
import Parse from 'parse/dist/parse.min.js';
const restoExists = async (restoId) => {
  const res = await firebase.firestore().collection('Restaurant').doc(restoId).get();
  return res.exists;
}

const RestaurantPick = (props) => {
  const [selectedResto, setSelectedResto] = useState({});
  const [error, setError] = useState('');

  const initParse =  restoId => new Promise(async (resolve, reject) => {
    const userDoc = await firebase.firestore().collection('Restaurant').doc(restoId).get({ source: 'server' })
      if (!userDoc.exists) return
      const backofficeUrl = userDoc.data().backoffice?.url
      const backofficeAppId = await userDoc.data().backoffice?.appId
      const backofficeKey = await userDoc.data().backoffice?.key
      if (backofficeUrl && backofficeAppId && backofficeKey) {
        Parse.initialize(
          backofficeAppId, // This is your Application ID
          backofficeKey // This is your Javascript key
        )
        Parse.serverURL = backofficeUrl
        resolve("I am done");
      } else {
        resolve("I am done 2");
      }
  });
  const confirmCallback = useCallback(async (selectedResto) => {
    await initParse(selectedResto.id)
    setError('');
    if (props.user.isAdmin)
      restoExists(selectedResto.id).then(exists => {
        if (exists)
          props.setResto(selectedResto);
        else
          setError('Le restaurant n\'existe pas.');
      });
    else
      props.setResto(selectedResto);
  }, [{}, props]);

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10px' }} className='ea-blue-bg'>
      <img src='./logo-easoft-no-bg.png' alt='Logo EASoft' />
      <Header as='h1' inverted textAlign='center'>
        Back Office
      </Header>

      <div className='login-form'>
        <Grid style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ width: 500 }}>
            <Segment raised style={{ padding: '50px 75px' }}>
              <Form size='large' onSubmit={null}>
                {props.user.isAdmin ? (
                  <>
                    <Header as='h3'>Entrez l'id du restaurant à afficher</Header>

                    <Form.Input
                      type='number'
                      onChange={(e, { value }) => setSelectedResto({ name: '', id: value })}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', justifyContent: 'center' }}>
                      <Button positive size='big' onClick={() => confirmCallback(selectedResto)}>Ok</Button>
                    </div>

                  </>
                ) : (
                  <>
                    <Header textAlign='center' as='h3'>Choisissez le restaurant à afficher</Header>
                    <div className='resto-pick-choice'>
                      {props.allRestos.map(resto => (
                        // <button className="px-4 py-1 text-sm text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">{resto.name}</button>
                        <div onClick={() => confirmCallback(resto)} key={resto.id} style={{ width: '200px', backgroundColor: 'lightgrey', padding: '10px', margin: '5px auto', textAlign: 'center', cursor: 'pointer' }}>
                          {resto.name}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </Form>
              {props.previousResto !== null && (
                <Button
                  basic
                  icon='arrow left'
                  size='big'
                  onClick={() => props.setResto(props.previousResto)}
                  style={{ position: 'absolute', top: 5, left: 5, boxShadow: 'none' }}
                />
              )}
              {error !== '' && (
                <Message error>{error}</Message>
              )}
            </Segment>
          </Grid.Column>
        </Grid>
      </div>

      <WaveSVG style={{ position: 'fixed', bottom: 0, zIndex: '-1' }} />
    </div >
  )
}

export default connect(
  (state) => ({
    allRestos: state.auth.allRestos,
    previousResto: state.resto.previousResto,
    user: state.auth.user,
  }),
  (dispatch) => ({
    setResto: (resto) => dispatch(setResto(resto)),
  })
)(RestaurantPick)