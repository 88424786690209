import React, { useState, useCallback } from 'react';
import { Container, Form, Button, Message, Table, Modal } from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import AddRestoModal from './AddRestoModal';
import RemoveRestoModal from './RemoveRestoModal';
import { createPortal } from 'react-dom';

const addResto = (userId, resto) => {
  firebase.firestore().collection('Restaurant').doc(resto.id).set({}, { merge: true })
  return firebase.firestore().collection('user').doc(userId).update({
    resto: firebase.firestore.FieldValue.arrayUnion(resto),
    restoId: firebase.firestore.FieldValue.arrayUnion(Number.parseInt(resto.id)),
  });
}

const removeResto = (userId, resto) => {
  return firebase.firestore().collection('user').doc(userId).update({
    resto: firebase.firestore.FieldValue.arrayRemove(...resto),
    restoId: firebase.firestore.FieldValue.arrayRemove(...resto.map(r => Number.parseInt(r.id))),
  });
}

const getUsers = async (email, id) => {
  if (email !== null) {
    const end = email.replace(
      /.$/, c => String.fromCharCode(c.charCodeAt(0) + 1),
    );
    const users = await firebase.firestore().collection('user')
      .where('email', '>=', email)
      .where('email', '<', end)
      .get();
    return users.docs.map(u => ({ id: u.id, ...u.data() }));
  }
  if (id !== null) {
    const users = await firebase.firestore().collection('user')
      .where('restoId', 'array-contains', id)
      .get();
    return users.docs.map(u => ({ id: u.id, ...u.data() }));
  }
}

const AccountCreation = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailSearch, setEmailSearch] = useState('');
  const [idSearch, setIdSearch] = useState('');
  const [lastSearchType, setLastSearchType] = useState('');

  const [addModalUser, setAddModalUser] = useState(null);
  const [removeModalUser, setRemoveModalUser] = useState(null);

  const [users, setUsers] = useState(null);

  const createAccount = useCallback(() => {
    setMessage(null);
    setLoading(true);
    firebase.app('admin').auth().createUserWithEmailAndPassword(email, new Date().getTime().toString())
      .then(user => {
        firebase.firestore().collection('user').doc(user.user.uid).set({ resto: [], restoId: [], activated: false, email: email })
          .then(() => {
            user.user.sendEmailVerification();
            setMessage({
              type: 'success',
              message: 'Le compte a été crée. Un email a été envoyé à l\'adresse email.',
            });

            firebase.app('admin').auth().signOut();
            setLoading(false);
          })
          .catch(() => {
            setMessage({
              type: 'error',
              message: 'Une erreur s\'est produite, le compte n\'a pas été crée.',
            });
            setLoading(false);
            user.user.delete();
          });
      })
      .catch((e) => {
        setMessage({
          type: 'error',
          message: 'Une erreur s\'est produite, le compte n\'a pas été crée.',
        });
        setLoading(false);
      });
  }, [email]);

  const searchUsers = useCallback((email, id) => {
    if (id !== null) {
      setLastSearchType('id');
      const numId = Number.parseInt(id);
      if (!Number.isNaN(numId))
        getUsers(null, numId).then(users => {
          setUsers(users);
        });
    }
    else {
      setLastSearchType('email');
      getUsers(email, null).then(users => {
        setUsers(users);
      });
    }

  }, []);

  const refreshData = useCallback(() => {
    setAddModalUser(null);
    setRemoveModalUser(null);
    if (lastSearchType === 'id') {
      searchUsers(null, idSearch);
    }
    else {
      searchUsers(emailSearch, null);
    }
  }, [lastSearchType]);

  return (
    <Container style={{ padding: 20 }}>
      <h3>Créer un nouveau compte</h3>
      {message !== null &&
        <Message
          error={message.type === 'error'}
          success={message.type === 'success'}
        >{message.message}</Message>
      }
      <Form onSubmit={null}>
        <Form.Input
          label='Adresse email'
          value={email}
          onChange={(e, { value }) => setEmail(value)}
        />
        <Button
          primary
          loading={loading}
          disabled={loading}
          onClick={createAccount}
        >Créer</Button>

        <h3>Recherche utilisateurs</h3>
        <Form onSubmit={null}>
          <Form.Group inline>
            <Form.Input
              label='Adresse email'
              value={emailSearch}
              onChange={(e, { value }) => setEmailSearch(value)}
            />
            <Button onClick={() => searchUsers(emailSearch, null)}>Chercher par email</Button>
          </Form.Group>
        </Form>
        <Form onSubmit={null}>
          <Form.Group inline>
            <Form.Input
              label='License resto'
              value={idSearch}
              type='number'
              onChange={(e, { value }) => setIdSearch(value)}
            />
            <Button onClick={() => searchUsers(null, idSearch)}>Chercher par license</Button>
          </Form.Group>
        </Form>
        {users &&
          <Table unstackable>
            <Table.Header>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Nombre restos</Table.HeaderCell>
              <Table.HeaderCell>Activé?</Table.HeaderCell>
              <Table.HeaderCell>Ajouter un resto</Table.HeaderCell>
              <Table.HeaderCell>Retirer un resto</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {users.map(u => (
                <Table.Row key={u.id}>
                  <Table.Cell>{u.email}</Table.Cell>
                  <Table.Cell textAlign='center'>{u.resto.length}</Table.Cell>
                  <Table.Cell textAlign='center'>{u.activated ? 'Oui' : 'Non'}</Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Button
                      primary
                      icon='plus'
                      onClick={() => setAddModalUser(u)}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Button
                      color='red'
                      icon='minus'
                      onClick={() => setRemoveModalUser(u)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        }
      </Form>

      {/* Add resto modal */}
      {addModalUser !== null && (
        <AddRestoModal
          open={addModalUser !== null}
          user={addModalUser}
          onClose={() => setAddModalUser(null)}
          onSubmit={(resto) => addResto(addModalUser.id, resto).then(() => refreshData())}
        />
      )}

      {/* Remove resto modal */}
      {removeModalUser && (
        <RemoveRestoModal
          open={removeModalUser !== null}
          user={removeModalUser}
          onClose={() => setRemoveModalUser(null)}
          onSubmit={(resto) => removeResto(removeModalUser.id, resto).then(() => refreshData())}
        />
      )}
    </Container>
  )
}

export default AccountCreation;