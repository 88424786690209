import { useEffect, useMemo, useState } from 'react';

function getStats(finishedOrders) {
  let totalTableTtc = 0.00
  let totalDeliveryTtc = 0.00
  let totalTakeAwayTtc = 0.00
  let totalBarTtc = 0.00
  let totalTable = 0
  let totalTable2 = 0
  let totalTable3 = 0
  let totalDelivery = 0
  let totalBar = 0
  let totalPerson = 0
  let totalTakeAway = 0
  let totalTtc = 0
  let totalHt = 0
  let totalHt1 = 0
  let totalHt2 = 0
  let totalHt3 = 0
  let totalTtc1 = 0
  let totalTtc2 = 0
  let totalTtc3 = 0
  let totalVat1 = 0
  let totalVat2 = 0
  let totalVat3 = 0
  let vatRate1 = 0
  let vatRate2 = 0
  let vatRate3 = 0
  let ESP = 0
  let CB = 0
  let TR = 0
  let CQ = 0
  let CV = 0
  let Autre = 0
  let Credit = 0
  let Autre2 = 0
  let Autre3 = 0
  let totalTtcCurrent = 0.00
  let totalCredit = 0
  for (let i = 0; i < finishedOrders.length; i++) {
    const order = finishedOrders[i];
    vatRate1 = order.vatRate1;
    vatRate2 = order.vatRate2;
    vatRate3 = order.vatRate3;
    if (order.canceled) continue;
    if (order.type === 'sit' || order.type === 'sitCustom') {
      totalTable++;
      totalTableTtc += order.totalTtc;
      if (Number.isInteger(order.nbCustomer))
        totalPerson += order.nbCustomer;
    }
    else if (order.type === 'sit2') {
      totalTable2++;
      totalTableTtc += order.totalTtc;
      if (Number.isInteger(order.nbCustomer))
        totalPerson += order.nbCustomer;
    }
    else if (order.type === 'sit3') {
      totalTable3++;
      totalTableTtc += order.totalTtc;
      if (Number.isInteger(order.nbCustomer))
        totalPerson += order.nbCustomer;
    }
    else if (order.type === 'delivery') {
      totalDelivery++;
      totalDeliveryTtc += order.totalTtc;
    }
    else if (order.type === 'takeAway') {
      totalTakeAway++;
      totalTakeAwayTtc += order.totalTtc;
    }
    else if (order.type === 'bar') {
      totalBar++;
      totalBarTtc += order.totalTtc;
    }
    totalTtc += order.totalTtc;
    totalHt1 += order.totalHt1;
    totalHt2 += order.totalHt2;
    totalHt3 += order.totalHt3;
    totalHt = totalHt1 + totalHt2 + totalHt3;
    totalVat1 += order.totalTtc1 - order.totalHt1;
    totalVat2 += order.totalTtc2 - order.totalHt2;
    totalVat3 += order.totalTtc3 - order.totalHt3;
    totalTtc1 += order.totalTtc1;
    totalTtc2 += order.totalTtc2;
    totalTtc3 += order.totalTtc3;
    const payments = Object.values(order.payments)
    payments.forEach((p) => {
      const amount = p.amount
      switch (p.id) {
        case 1:
          ESP += amount;
          break;
        case 2:
          CB += amount;
          break;
        case 3:
          TR += amount;
          break;
        case 4:
          CQ += amount;
          break;
        case 5:
          CV += amount;
          break;
        case 6:
          Autre += amount;
          break;
        case 7:
          Credit += amount;
          break;
        case 8:
          Autre2 += amount;
          break;
        case 9:
          Autre3 += amount;
          break;
        default:
          break;
      }
    })
    if (order.paybackCash !== null && order.paybackCash > 0)
      ESP -= order.paybackCash;
  }
  return {
    payment: {
      totalPayment1: ESP,
      totalPayment2: CB,
      totalPayment3: TR,
      totalPayment4: CQ,
      totalPayment5: CV,
      totalPayment6: Autre,
      totalPayment7: Credit,
      totalPayment8: Autre2,
      totalPayment9: Autre3,
      labels: [
        'Espèce',
        'Carte bleu',
        'Ticket restaurant',
        'Chèque',
        'Chèque vacance',
        'Autre',
        'Autre 2',
        'Autre 3',
      ],
      datasets: [{
        data: [ESP.toFixed(2), CB.toFixed(2), TR.toFixed(2), CQ.toFixed(2), CV.toFixed(2), Autre.toFixed(2), Autre2.toFixed(2), Autre3.toFixed(2)],
        backgroundColor: ['#f08344', '#1774b1', '#55c2fc', '#e11b65', '#c969bc', '#8c2f0e', '#43c2ae', '#b89d4e']
      }]
    },
    turnover: {
      totalTtc,
      totalHt,
      totalHt1,
      totalHt2,
      totalHt3,
      totalVat1,
      totalVat2,
      totalVat3,
      totalTtc1,
      totalTtc2,
      totalTtc3,
      vatRate1,
      vatRate2,
      vatRate3,
    },
    statistic: {
      totalTableTtc,
      totalDeliveryTtc,
      totalTakeAwayTtc,
      totalBarTtc,
      totalTable,
      totalTable2,
      totalTable3,
      totalDelivery,
      totalBar,
      totalPerson,
      totalTakeAway,
      averageCart: totalTtc / (totalDelivery + totalBar + totalTakeAway + totalTable + totalTable2 + totalTable3),
      averagePerson: totalTableTtc / totalPerson
    }
  }
}
function getProductStats(finishedOrders) {
  const productStatsDay = []
  let totalTTC = 0
  for (let j = 0; j < finishedOrders.length; j++) {
    if (finishedOrders[j].canceled === false) {
      const products = Object.values(finishedOrders[j].products)
      for (let k = 0; k < products.length; k++) {
        const cartProduct = products[k]
        if (cartProduct.type === 'option' && cartProduct.priceTtc === 0) continue
        if (cartProduct.type === 'comment' || cartProduct.type === 'next') continue
        const p = productStatsDay.filter(product => product.name === cartProduct.name)[0]
        if (p) {
          if (cartProduct.totalTtc === 0) p.freeQuantity += cartProduct.quantity
          p.quantity += (cartProduct.gram === true ? 1 : cartProduct.quantity)
          p.totalTtc += cartProduct.totalTtc
          totalTTC += cartProduct.totalTtc
          if (cartProduct.totalTtc !== 0)
            p.totalHt += cartProduct.priceHt * (cartProduct.gram === true ? cartProduct.quantity / 100 : cartProduct.quantity)
        } else {
          totalTTC += cartProduct.totalTtc === 0 ? 0 : (cartProduct.priceTtc * (cartProduct.gram === true ? cartProduct.quantity / 100 : cartProduct.quantity))
          productStatsDay.push({
            name: cartProduct.name,
            quantity: (cartProduct.gram === true ? 1 : cartProduct.quantity),
            freeQuantity: cartProduct.totalTtc === 0 ? cartProduct.quantity : 0,
            totalTtc: cartProduct.totalTtc === 0 ? 0 : (cartProduct.priceTtc * (cartProduct.gram === true ? cartProduct.quantity / 100 : cartProduct.quantity)),
            totalHt: cartProduct.totalTtc === 0 ? 0 : (cartProduct.priceHt * (cartProduct.gram === true ? cartProduct.quantity / 100 : cartProduct.quantity)),
          })
        }
      }
    }
  }

  productStatsDay.sort((a, b) => b.quantity - a.quantity)
  return productStatsDay;
}

const useOrderStats = (tickets, serveur = 0) => {
  const stats = useMemo(() => {
    if (!tickets || tickets.length === 0) {
      return {
        ticket: [],
        statistic: { totalPerson: 0, averageCart: 0 },
        turnover: { totalTtc: 0 },
      }
    }

    else if (tickets.length > 0) {
      let filteredTickets = tickets;
      if (serveur !== 0)
        filteredTickets = filteredTickets.filter(t => t.userId === serveur);

      const data = getStats(filteredTickets);
      data.productStat = getProductStats(filteredTickets);
      data.ticket = filteredTickets;
      return data;
    }
  }, [tickets, serveur]);
  return stats;
};

export default useOrderStats;