import React, { useState } from 'react';
import { Table, Modal, Button, Icon } from 'semantic-ui-react';

const getTypeString = (type) => {
  switch (type) {
    case 'bar': return 'Commande';
    case 'sit': return 'Table';
    case 'takeAway': return 'Emporté';
    case 'delivery': return 'Livraison';
    default: return '';
  }
}

const vatIdToString = (id) => {
  switch (id) {
    case 1: return 'A';
    case 2: return 'B';
    case 3: return 'C';
    default: return '';
  }
}

const dateOptions = {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
};

const timeOptions = {
  hour: '2-digit',
  minute: '2-digit',
}

/**
 * Tableau qui affiche les tickets en props
 * @param data: [Ticket]
 */
const TicketsTable = (props) => {
  const [clickedTicket, setClickedTicket] = useState(null);

  return (
    <div className='table-height'>

      {/* Tableau de tickets */}
      <Table unstackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Heure</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Table</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Montant</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Serveur</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Voir</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(props.data && props.data.length > 0) ? props.data.map((t, idx) => (
            <Table.Row onClick={() => setClickedTicket(t)} style={t.canceled ? { backgroundColor: '#D0D0D0', cursor: 'pointer' } : { cursor: 'pointer' }} key={idx}>
              <Table.Cell>{t.finishedAt.toDate ? t.finishedAt.toDate().toLocaleTimeString('fr-FR', timeOptions) : t.finishedAt.toLocaleTimeString('fr-FR', timeOptions)}</Table.Cell>
              <Table.Cell textAlign='center'>{t.table || "/"}</Table.Cell>
              <Table.Cell textAlign='right'>{t.totalTtc.toFixed(2) || "/"}</Table.Cell>
              <Table.Cell textAlign='right'>{t.userName || "/"}</Table.Cell>
              <Table.Cell textAlign='center'><Icon name='eye' /></Table.Cell>
            </Table.Row>
          )) : (
            <Table.Row>
              <Table.Cell colSpan='5'>Pas de données</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      {/* Modale pour afficher le ticket séléctionné */}
      {clickedTicket !== null &&
        <Modal
          onClose={() => setClickedTicket(null)}
          open={clickedTicket !== null}
          style={{ maxWidth: 360, padding: '0 30px 30px 30px' }}
        >
          <Modal.Content>
            {/* Header */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                Date: {clickedTicket.finishedAt.toDate ? clickedTicket.finishedAt.toDate().toLocaleDateString('fr-FR', dateOptions) : clickedTicket.finishedAt.toLocaleDateString('fr-FR', dateOptions)} {clickedTicket.finishedAt.toDate ? clickedTicket.finishedAt.toDate().toLocaleTimeString('fr-FR') : clickedTicket.finishedAt.toLocaleTimeString('fr-FR')}<br />
                Ticket {clickedTicket.canceledForTicketId && 'd\'annulation'} n°{clickedTicket.id} {clickedTicket.canceledForTicketId && 'pour le ticket n°' + clickedTicket.canceledForTicketId}
              </div>
              <Button basic icon='close' onClick={() => setClickedTicket(null)} />
            </div>
            <h3>{getTypeString(clickedTicket.type)} {clickedTicket.table || ''}</h3>
            <hr />

            {/* Products */}
            <Table basic='very' unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>QTE</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>P.U.</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                  <Table.HeaderCell>TVA</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.values(clickedTicket.products).map((p, idx) => (
                  <>
                    <Table.Row key={idx}>
                      <Table.Cell>{p.quantity}</Table.Cell>
                      <Table.Cell style={{ maxWidth: 130, overflow: 'hidden', textOverflow: 'ellipsis' }}>{p.name}</Table.Cell>
                      <Table.Cell>{p.discount === 100 ? 'Offert' : p.priceTtc.toFixed(2)}</Table.Cell>
                      <Table.Cell>{p.discount === 100 ? '' : p.totalTtc.toFixed(2)}</Table.Cell>
                      <Table.Cell>{vatIdToString(p.vatId)}</Table.Cell>
                    </Table.Row>
                    {p.discount > 0 && p.discount < 100 && (
                      <Table.Row>
                        <Table.Cell textAlign='right' colSpan='3' style={{ borderTop: 'none' }}>Remise {p.discount}%</Table.Cell>
                        <Table.Cell style={{ borderTop: 'none' }}>-{p.totalDiscountTtc.toFixed(2)}</Table.Cell>
                      </Table.Row>
                    )}
                  </>
                ))}
              </Table.Body>
            </Table>
            <hr />

            {/* Footer */}
            {clickedTicket.discount !== 0 && (
              <div style={{ textAlign: 'right' }}>
                <span>{clickedTicket.discount === 100 ? 'Offert ' : 'Remise ' + clickedTicket.discount}%  &nbsp;&nbsp;&nbsp;&nbsp; -{clickedTicket.totalDiscountTtc.toFixed(2)} EUR</span>
              </div>
            )}
            <h3 style={{ display: 'flex', justifyContent: 'space-between', marginTop: 0 }}>
              <span>Total TTC</span>
              <span>{clickedTicket.totalTtc.toFixed(2)} EUR</span>
            </h3>
            <Table fixed basic='very' unstackable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>TVA</Table.Cell>
                  <Table.Cell>Mt. TVA</Table.Cell>
                  <Table.Cell>Base HT</Table.Cell>
                  <Table.Cell>Base TTC</Table.Cell>
                </Table.Row>
                {clickedTicket.totalVat1 ? (
                  <Table.Row>
                    <Table.Cell>TVA A ({clickedTicket.vatRate1.toFixed(2)}%)</Table.Cell>
                    <Table.Cell>{clickedTicket.totalVat1.toFixed(2)}</Table.Cell>
                    <Table.Cell>{clickedTicket.totalHt1.toFixed(2)}</Table.Cell>
                    <Table.Cell>{clickedTicket.totalTtc1.toFixed(2)}</Table.Cell>
                  </Table.Row>
                ) : null}
                {clickedTicket.totalVat2 ? (
                  <Table.Row>
                    <Table.Cell>TVA B ({clickedTicket.vatRate2.toFixed(2)}%)</Table.Cell>
                    <Table.Cell>{clickedTicket.totalVat2.toFixed(2)}</Table.Cell>
                    <Table.Cell>{clickedTicket.totalHt2.toFixed(2)}</Table.Cell>
                    <Table.Cell>{clickedTicket.totalTtc2.toFixed(2)}</Table.Cell>
                  </Table.Row>
                ) : null}
                {clickedTicket.totalVat3 ? (
                  <Table.Row>
                    <Table.Cell>TVA C ({clickedTicket.vatRate3.toFixed(2)}%)</Table.Cell>
                    <Table.Cell>{clickedTicket.totalVat3.toFixed(2)}</Table.Cell>
                    <Table.Cell>{clickedTicket.totalHt3.toFixed(2)}</Table.Cell>
                    <Table.Cell>{clickedTicket.totalTtc3.toFixed(2)}</Table.Cell>
                  </Table.Row>
                ) : null}
                <Table.Row>
                  <Table.Cell>Total</Table.Cell>
                  <Table.Cell>{clickedTicket.totalVat.toFixed(2)}</Table.Cell>
                  <Table.Cell>{clickedTicket.totalHt.toFixed(2)}</Table.Cell>
                  <Table.Cell>{clickedTicket.totalTtc.toFixed(2)}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            {Object.values(clickedTicket.payments).length > 0 && (
              <>
                <hr />
                <div style={{ textAlign: 'center', paddingTop: 10 }}>
                  <b>Recu règlement:</b>
                  {Object.values(clickedTicket.payments).map((p, idx) => (
                    <div key={idx}>
                      <br />
                      <span>*{p.name}: {p.amount.toFixed(2)} EUR</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Modal.Content>
        </Modal>
      }
    </div>
  );
}

export default TicketsTable;