import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import { Button, Grid, Message } from 'semantic-ui-react';
import * as CSV from 'csv-string'

const parseCsv = (csv) => {
  const products = [];
  const lines = CSV.parse(csv);

  for (let i = 1; i < lines.length; ++i) {
    const line = lines[i];
    if (line.length !== 4)
      throw new Error('Vérifiez la ligne ' + (i + 1) + ' de votre fichier csv.');

    const product = {};
    product.name = line[0];
    product.description = line[1];
    product.unitPrice = Number.parseFloat(line[2]);

    if (Number.isNaN(product.unitPrice)) {
      throw new Error('Vérifiez le prix à ligne ' + (i + 1) + ' de votre fichier csv. ');
    }

    product.vat = Number.parseFloat(line[3]);

    if (Number.isNaN(product.vat)) {
      throw new Error('Vérifiez la TVA à ligne ' + (i + 1) + ' de votre fichier csv. ');
    }

    product.unitPriceHT = parseFloat((product.unitPrice / ((100 + product.vat) / 100)).toFixed(2));
    product.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    product.displayApp = true;
    product.canOrder = true;
    product.categoryId = null;
    product.image = {
      path: '',
      enable: false
    };
    product.tableDiscount = {
      discount: 0,
      canDiscount: true,
    };
    product.deliveryDiscount = {
      discount: 0,
      canDiscount: true,
    };
    product.takeawayDiscount = {
      discount: 0,
      canDiscount: true,
    };
    product.rubric = [];

    products.push(product);
  }
  console.log(products)
  return products;
};

const batchCreateProducts = (restoId, products) => {
  const productRef = firebase.firestore().collection('Restaurant').doc(restoId).collection('Product');
  const batch = firebase.firestore().batch();
  for (let i = 0; i < products.length; ++i) {
    const product = products[i];
    batch.set(productRef.doc(), product);
  }
  return batch.commit();
}

export default function CSVReader(props) {
  const [products, setProducts] = useState(null);
  const [message, setMessage] = useState('');
  const [filename, setFilename] = useState('');
  const [loading, setLoading] = useState(false);

  const uploadProducts = useCallback(() => {
    if (loading) return;

    setLoading(true);
    batchCreateProducts(props.restoId, products).then(() => {
      setLoading(false);
      setMessage('Les produits ont été crée avec succes.');
      setFilename('');
      setProducts(null);
    }).catch(() => {
      setMessage('Une erreur s\'est produite lors de la création des produits');
      setLoading(false);
    });
  }, [products, props, loading])

  const onDrop = useCallback(acceptedFiles => {
    setMessage('');
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const str = reader.result;
        console.log(str);
        try {
          setProducts(parseCsv(str));
        } catch (e) {
          setMessage('Votre fichier csv contient une erreur. ' + (e.message || ''));
        }
      }
      reader.readAsText(file);
      setFilename(file.path);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Placer un CSV ici.</p>
      </div>
      <Grid columns="2" padded>
        {message && (
          <Grid.Row>
            <Grid.Column>
              <Message>
                {message}
              </Message>
            </Grid.Column>
            <Grid.Column>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            {filename || 'Pas de fichier.'}
          </Grid.Column>
          <Grid.Column textAlign='right'>
            <Button
              primary
              disabled={products === null || loading === true}
              onClick={uploadProducts}
              loading={loading}
            >
              Valider
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
