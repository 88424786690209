import React, { useCallback, useState } from 'react';
import { Modal, Menu, Button, Form, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import XLSX from 'xlsx';

const makeSheet = (data, resto = {}, date, venteOption = true, statOption = true) => {
  const workbook = XLSX.utils.book_new();

  if (statOption) {
    const aoaStat = [
      [resto.name || '', resto.postal || ''],
      ['Z du', date.toLocaleDateString('fr-FR')],
      [''],
      ['Chiffre d\'affaire', ''],
      ['Total', ''],
      ['HT', data.turnover.totalHt],
      ['TTC', data.turnover.totalTtc],
      ['Ventilation TVA', ''],
      ['TVA ' + data.turnover.vatRate1 + '%', {f: `ROUND(${data.turnover.totalHt1}, 2)`}],
      ['TVA ' + data.turnover.vatRate2 + '%', {f: `ROUND(${data.turnover.totalHt2}, 2)`}],
      ['TVA ' + data.turnover.vatRate3 + '%', {f: `ROUND(${data.turnover.totalHt3}, 2)`}],
      ['TVA Collectée', ''],
      ['TVA ' + data.turnover.vatRate1 + '%', {f: `ROUND(${data.turnover.totalVat1}, 2)`}],
      ['TVA ' + data.turnover.vatRate2 + '%', {f: `ROUND(${data.turnover.totalVat2}, 2)`}],
      ['TVA ' + data.turnover.vatRate3 + '%', {f: `ROUND(${data.turnover.totalVat3}, 2)`}],
      [''],
      ['Statistique', '', ''],
      ['', 'Nombre', 'Total TTC'],
      ['Nb client', {f: `ROUND(${data.statistic.totalPerson}, 2)`}, ''],
      ['Sur place', {f: `ROUND(${data.statistic.totalTable}, 2)`}, {f: `ROUND(${data.statistic.totalTableTtc}, 2)`}],
      ['Bar', {f: `ROUND(${data.statistic.totalBar}, 2)`}, {f: `ROUND(${data.statistic.totalBarTtc}, 2)`}],
      ['Emporté',{f: `ROUND(${data.statistic.totalTakeAway}, 2)`},{f: `ROUND(${data.statistic.totalTakeAwayTtc}, 2)`}],
      ['Livraison', {f: `ROUND(${data.statistic.totalDelivery}, 2)`}, {f: `ROUND(${data.statistic.totalDeliveryTtc}, 2)`}]
    ];

    aoaStat.push(['']);

    aoaStat.push(['Modes de paiements', '']);
    aoaStat.push(['Paiement', 'Montant']);

    for (let i = 0; i < data.payment.labels.length; ++i) {
      aoaStat.push([
        data.payment.labels[i],
        {f: `ROUND(${data.payment.datasets[0].data[i]}, 2)`},
      ]);
    }

    const statSheet = XLSX.utils.aoa_to_sheet(aoaStat);
    statSheet['!merges'] = [
      { s: {r: 3, c: 0}, e: {r: 3, c: 1} },
      { s: {r: 4, c: 0}, e: {r: 4, c: 1} },
      { s: {r: 7, c: 0}, e: {r: 7, c: 1} },
      { s: {r: 11, c: 0}, e: {r: 11, c: 1} },
      { s: {r: 16, c: 0}, e: {r: 16, c: 2} },
      { s: {r: 25, c: 0}, e: {r: 25, c: 1} },
    ];

    XLSX.utils.book_append_sheet(workbook, statSheet, 'Statistiques');
  }

  if (venteOption) {
    const aoa = [
      [resto.name || '', resto.postal || ''],
      ['Z du', date.toLocaleDateString('fr-FR')],
      ['']
      ['Tickets'],
      ['COMMANDE', 'DATE', 'TABLE', 'MONTANT', 'SERVEUR'],
    ];

    for (let i = 0; i < data.ticket.length; ++i) {
      const ticket = data.ticket[i];

      aoa.push([
        ticket.id,
        ticket.finishedAt.toLocaleDateString('fr-FR') + ' à ' + ticket.finishedAt.toLocaleTimeString('fr-FR'),
        ticket.table,
        {f: `ROUND(${ticket.totalTtc}, 2)`},
        ticket.userName,
      ]);
    }

    aoa.push(['']);

    aoa.push(['Meilleurs ventes'])

    aoa.push([
      'DESCRIPTION',
      'QTT',
      'DONT OFFERT',
      'MONTANT'
    ]);

    for (let i = 0; i < Math.min(data.productStat.length, 30); ++i) {
      const product = data.productStat[i];

      aoa.push([
        product.name,
        product.quantity,
        product.freeQuantity,
        {f: `ROUND(${product.totalTtc}, 2)`},
      ]);
    }

    const sheet = XLSX.utils.aoa_to_sheet(aoa);

    XLSX.utils.book_append_sheet(workbook, sheet, 'Ventes');
  }

  const filename = `${resto.name} - Z ${date.toLocaleDateString('fr-FR').replaceAll('/', '-')}`;
  return XLSX.writeFile(workbook, filename + '.xlsx');
}

const ExportExcel = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [venteOption, setVenteOption] = useState(false);
  const [statOption, setStatOption] = useState(true);

  const showCallback = useCallback(() => setShowModal(true), []);
  const hideCallback = useCallback(() => setShowModal(false), []);
  const makeSheetCallback = useCallback(() => {
    makeSheet(props.data, props.resto, props.date, venteOption, statOption);
    setShowModal(false);
  }, [props, venteOption, statOption]);

  return (
    <>
    <Menu.Menu position='right'>
      <Menu.Item
        onClick={showCallback}
      >
        Exporter en Excel
      </Menu.Item>
    </Menu.Menu>
    <Modal open={showModal} onClose={hideCallback}>
      <Modal.Header>
        Selectionner les données à exporter
      </Modal.Header>

      <Modal.Content>
        <Form onSubmit={null}>
          <Form.Field>
            <Checkbox checked={statOption} onChange={() => setStatOption(!statOption)} label='Statistiques' />
          </Form.Field>
          <Form.Field>
            <Checkbox checked={venteOption} onChange={() => setVenteOption(!venteOption)} label='Ventes' />
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={hideCallback}>
          Annuler
        </Button>
        <Button disabled={!statOption && !venteOption} primary onClick={makeSheetCallback}>
          Exporter
        </Button>
      </Modal.Actions>
    </Modal>
    </>
  );
}

export default connect(
  (state) => ({
    resto: state.resto.currentResto,
  })
)(ExportExcel);