import {
  LOGIN,
  LOGOUT,
} from '../actionTypes';

const initialState = {
  allRestos: [],
  user: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        allRestos: action.payload.allRestos,
        user: action.payload.user,
      }
    }
    case LOGOUT: {
      return {
        ...initialState,
      }
    }
    default:
      return state;
  }
}