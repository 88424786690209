import {
  SET_RESTO,
  REMOVE_RESTO,
} from '../actionTypes';

const initialState = {
  previousResto: null,
  currentResto: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_RESTO: {
      return {
        ...state,
        previousResto: action.payload.resto,
        currentResto: action.payload.resto,
      }
    }
    case REMOVE_RESTO: {
      return {
        ...state,
        currentResto: null,
      }
    }
    default:
      return state;
  }
}