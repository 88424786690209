import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';


/**
 * Componsant permettant de cacher la sidebar. N'apparait que sur les ecrans taille mobile
 * @props onVisibilityChange: (string?) => void
 */
const SideBarToggleButton = (props) => {
  useEffect(() => {
    props.onVisibilityChange('mounted');
    return () => props.onVisibilityChange(null);
  }, []);

  return (
    <div style={{ margin: 10 }}>
      <Button size='big' basic icon='bars' onClick={() => props.onVisibilityChange('clicked')} style={{boxShadow: 'none'}} />
    </div>
  );
}

export default SideBarToggleButton;