import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import ColorPicker from './ColorPicker';
import CategoryIconPicker from './CategoryIconPicker';

/**
 * Formulaire qui s'affiche quand le dernier élément cliqué est une catégorie.
 */
const CategoryForm = (props) => {
  const [input, setInput] = useState(props.category.name);
  const [color, setColor] = useState(props.category.color);
  const [logo, setLogo] = useState(props.category.logo);

  return (
    <Form>
      <Form.Input
        label='Nom de la sous-catégorie'
        value={input}
        onChange={(e, { value }) => setInput(value)}
      />
      <Form.Group grouped>
        <label>Couleur</label><br/>
        <ColorPicker
          value={color}
          onChange={(color) => setColor(color)}
        />
      </Form.Group>
      <Form.Group grouped>
        <label>Icone</label><br/>
        <CategoryIconPicker
          value={logo}
          onChange={(logo) => setLogo(logo)}
        />
      </Form.Group>
      <Form.Group inline>
        <Form.Button
          primary
          onClick={() => {
            let result = {...props.category};
            result.name = input;
            result.color = color;
            result.logo = logo;
            props.onChange(result);
          }}
        >Confirmer</Form.Button>
        <Form.Button
          color='red'
          onClick={() => props.onDelete(props.category.id)}
        >Supprimer</Form.Button>
      </Form.Group>
    </Form>
  );
}

export default CategoryForm;