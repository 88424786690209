import {
  LOGIN,
  LOGOUT,
  SET_RESTO,
  REMOVE_RESTO,
} from './actionTypes';

export const login = payload => ({
  type: LOGIN,
  payload: {
    user: payload.user,
    allRestos: payload.allRestos
  },
});

export const logout = () => ({
  type: LOGOUT,
  payload: {},
});

export const setResto = (resto) => ({
  type: SET_RESTO,
  payload: {
    resto
  }
});

export const removeResto = () => ({
  type: REMOVE_RESTO,
  payload: {},
});