import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Breakpoint } from 'react-socks';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import { Grid, Container, Menu, Button } from 'semantic-ui-react';
import { CATable, MeilleursVentesChart, PaiementsChart, StatsTable, TicketsTable, OrdersTable, StatCard } from '../components/charts';
import useOrderStats from '../hooks/useOrderStats';
import useOrderStats2 from '../hooks/useOrderStats2';



const useRealTimeData = (restoId) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const subscribe = firebase.firestore().collection('Restaurant').doc(restoId).collection('live').doc('live').onSnapshot(snap => {
      setData(snap.data());
    });

    return subscribe;
  }, [restoId]);

  return data;
}

const RapportZ = (props) => {
  const rawData = useRealTimeData(props.resto.id);
  const [serveur, setServeur] = useState(0);
  const ticketData = useOrderStats(rawData ? rawData.ticket : null, serveur);
  const orderData = useOrderStats2(rawData ? rawData.order : null, serveur);
  const [flash, setFlash] = useState(false);

  const serveurOptions = useMemo(() => {
    if (rawData && rawData.user) {
      const options = rawData.user.map(u => ({
        text: u.name,
        key: u.id,
        value: u.id,
      }));

      options.unshift({
        text: 'Tous les serveurs',
        key: 0,
        value: 0,
      });

      return options;
    }
    else return [];
  }, [rawData]);

  // flashing effect
  useEffect(() => {
    setFlash(true);
    setTimeout(() => setFlash(false), 3500);
  }, [rawData]);

  if (!ticketData.ticket.length && !orderData.ticket.length)
    return (
      <Container>
        <Breakpoint small down style={{ position: 'absolute', top: '22px', left: '70px' }}>
          <h3>Temps réel</h3>
        </Breakpoint>
        <Grid padded stackable>
          <Grid.Column width={16}>
            <Menu className='serveur-picker'>
              {serveurOptions && serveurOptions.map(o => (
                <Menu.Item
                  as={Button}
                  key={o.key}
                  active={serveur === o.value}
                  onClick={() => setServeur(o.value)}
                >
                  {o.text}
                </Menu.Item>
              ))}
            </Menu>
          </Grid.Column>
        </Grid>
        <h2 className='center-absolute'>
          Pas de commande en cours.
        </h2>
      </Container>
    );

  return (
    <Container className={flash ? 'flashnew' : ''}>
      <Breakpoint small down style={{ position: 'absolute', top: '22px', left: '70px' }}>
        <h3>Temps réel</h3>
      </Breakpoint>
      <Grid padded stackable>
        <Grid.Column width={16}>
          <Menu className='serveur-picker'>
            {serveurOptions && serveurOptions.map(o => (
              <Menu.Item
                as={Button}
                key={o.key}
                active={serveur === o.value}
                onClick={() => setServeur(o.value)}
              >
                {o.text}
              </Menu.Item>
            ))}
          </Menu>
        </Grid.Column>


        <Grid.Column width={5}>
          <StatCard
            title='En cours (Commandes / CV)'
            value={`${orderData.totalTtc.toFixed(2)} (${orderData.ticket.length}/${orderData.totalPerson})`}
            color='#ED5394'
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <StatCard
            title='Encaissés (Commandes / CV)'
            value={`${ticketData.turnover.totalTtc.toFixed(2)}\n(${ticketData.ticket.length}/${ticketData.statistic.totalPerson})`}
            color={'#7DB6D6'}
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <StatCard
            title='Moyenne des tickets (TTC)'
            value={ticketData.statistic.averageCart ? ticketData.statistic.averageCart.toFixed(2) : '0.00'}
            color={'#20CD7A'}
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <StatCard
            title='Ticket moyen (TTC / CV)'
            value={ticketData.statistic.averagePerson ? ticketData.statistic.averagePerson.toFixed(2) : '0.00'}
            color={'#faa157'}
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <StatCard
            title='CA réalisé (TTC)'
            value={ticketData.turnover.totalTtc.toFixed(2)}
            color={'#466181'}
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <Grid stackable>
            <Grid.Column width={16}>
              <div className='column-container'>
                <h3 style={{ textAlign: 'center' }}>Commandes en cours</h3>
                <OrdersTable data={orderData.ticket ?? null} />
              </div>
            </Grid.Column>
            <Grid.Column width={16}>
              <div className='column-container'>
                <h3 style={{ textAlign: 'center' }}>Commandes encaissés</h3>
                <TicketsTable data={ticketData.ticket ?? null} />
              </div>
            </Grid.Column>
            <Grid.Column width={16}>
              <div className='column-container'>
                <h3 style={{ textAlign: 'center' }}>Chiffre d'affaire</h3>
                <CATable data={ticketData.turnover ?? null} />
              </div>
            </Grid.Column>
          </Grid>
        </Grid.Column>

        <Grid.Column width={10}>
          <Grid stackable>
            <Grid.Column width={8}>
              <div className='column-container'>
                <h3 style={{ textAlign: 'center' }}>Meilleures ventes</h3>
                <MeilleursVentesChart height={350} data={ticketData.productStat ?? []} />
              </div>
            </Grid.Column>

            <Grid.Column width={8}>
              <div className='column-container'>
                <h3 style={{ textAlign: 'center' }}>Modes de paiement</h3>
                <PaiementsChart data={ticketData.payment ?? []} />
              </div>
            </Grid.Column>

            <Grid.Column width={8}>
              <div className='column-container'>
                <h3 style={{ textAlign: 'center' }}>Statistique</h3>
                <StatsTable data={ticketData.statistic ?? []} />
              </div>
            </Grid.Column>

          </Grid>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

export default connect(
  (state) => ({
    resto: state.resto.currentResto,
  }),
)(RapportZ);